import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    TableCell,
    TableRow,
    TextField,
    useTheme,
} from "@mui/material";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";

export const UpdateStudentAttendanceRow = ({
    singleStudent,
    studentAttendance,
    setStudentAttendance,
    checkAll,
    limit,
    disable,
    id,
}: any) => {
    const theme = useTheme();
    const attendanceOptions = [
        {
            label: "Present",
            status: "present",
            id: "present",
            color: theme.palette.success.main,
        },
        {
            label: "Absent",
            status: "absent",
            id: "absent",
            color: theme.palette.error.main,
        },
        { label: "Leave", status: "leave", id: "leave", color: "#fdd835" },
        {
            label: "Late",
            status: "late",
            id: "late",
            color: theme.palette.warning.main,
        },

        {
            label: "Off Day",
            status: "offDay",
            id: "offDay",
            color: theme.palette.info.main,
        },
        {
            label: "Sick Leave",
            status: "sickleave",
            id: "sickLeave",
            color: theme.palette.secondary.main,
        },
    ];
    const [isChecked, setIsChecked] = useState(checkAll);
    const { feedback: _globalFeedBack } = useContext(AppContext);
    const [feedback, setFeedback] = useState(_globalFeedBack);
    function handleCheck(
        event: ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) {
        setIsChecked(checked);
        setStudentAttendance((curr: any) => {
            const withCurrChecked = {
                ...curr,
                [singleStudent.student.baseUser.id]: {
                    ...studentAttendance[singleStudent.student.baseUser.id],
                    checked,
                },
            };

            return withCurrChecked;
        });
    }

    const handleChange = (ev: ChangeEvent<any>, id: number) => {
        setIsChecked(true);
        const value = ev.target.value;
        const updated = {
            ...studentAttendance,
            [id]: {
                ...studentAttendance[id],
                [ev.target.name]: value,
            },
        };

        setStudentAttendance(updated);
    };

    useEffect(() => {
        setIsChecked(
            studentAttendance[singleStudent?.student.baseUser?.id]?.checked ??
                false
        );
    }, [studentAttendance[singleStudent.student.baseUser.id]?.checked]);

    return (
        <TableRow>
            <TableCell>
                <FormControl>
                    <Checkbox
                        checked={isChecked}
                        onChange={handleCheck}
                        disabled={disable}
                    />
                </FormControl>
            </TableCell>
            <TableCell>{id}</TableCell>
            <TableCell>{singleStudent.student.admissionNumber}</TableCell>
            <TableCell>{singleStudent.student.enrollmentNo}</TableCell>
            <TableCell>{singleStudent.student.fileNo}</TableCell>
            <TableCell>{singleStudent.student.id}</TableCell>
            <TableCell style={{ whiteSpace: "nowrap" }}>
                {singleStudent.student.baseUser.name}
            </TableCell>
            <TableCell style={{ whiteSpace: "nowrap" }}>
                {singleStudent.student.fatherName}
            </TableCell>

            <TableCell style={{ minWidth: "220px" }}>
                {/* <Autocomplete
                    defaultOptions={[
                        {
                            label: "Present",
                            status: "present",
                            id: "Present",
                        },
                        {
                            label: "Absent",
                            status: "absent",
                            id: "Absent",
                        },
                        {
                            label: "Leave",
                            status: "leave",
                            id: "Leave",
                        },

                        {
                            label: "Late",
                            status: "late",
                            id: "Late",
                        },
                    ]}
                    setOutput={(des: any) => {
                        setStudentAttendance((c: any) => ({
                            ...c,
                            [singleStudent.baseUser.id]: {
                                ...c[singleStudent.baseUser.id],
                                attendanceStatus: des?.id ?? "",
                                checked: true,
                                student: singleStudent.id,
                            },
                        }));
                    }}
                    labelKey="label"
                    label="Attendance Status"
                    textFieldProps={{
                        variant: "outlined",
                        size: "small",
                    }}
                    defaultValue={
                        studentAttendance[singleStudent.baseUser.id]
                            ?.attendanceStatus
                            ? {
                                  id: studentAttendance[
                                      singleStudent.baseUser.id
                                  ]?.attendanceStatus,
                                  label: studentAttendance[
                                      singleStudent.baseUser.id
                                  ]?.attendanceStatus,
                              }
                            : undefined
                    }
                    clear={Boolean(
                        studentAttendance[singleStudent.baseUser.id]
                            ?.attendanceStatus == ""
                    )}
                    key={
                        studentAttendance[singleStudent?.baseUser?.id]
                            ?.attendanceStatus
                    }
                /> */}

                <FormControl component="fieldset">
                    <RadioGroup
                        row
                        value={
                            studentAttendance[singleStudent.student.baseUser.id]
                                ?.attendanceStatus ?? ""
                        }
                        onChange={(event) => {
                            const selectedId = event.target.value;
                            setStudentAttendance((current: any) => ({
                                ...current,
                                [singleStudent.student.baseUser.id]: {
                                    ...current[
                                        singleStudent.student.baseUser.id
                                    ],
                                    attendanceStatus: selectedId ?? "",
                                    checked: true,
                                    student: singleStudent.student.id,
                                },
                            }));
                        }}
                        defaultValue={
                            studentAttendance[singleStudent.student.baseUser.id]
                                ?.attendanceStatus
                                ? {
                                      attendanceStatus:
                                          studentAttendance[
                                              singleStudent.student.baseUser.id
                                          ]?.attendanceStatus,
                                  }
                                : undefined
                        }
                    >
                        {attendanceOptions.map((option) => (
                            <FormControlLabel
                                key={option.id}
                                value={option.id}
                                control={
                                    <Radio
                                        style={{ color: `${option.color}` }}
                                        disabled={disable}
                                    />
                                }
                                label={option.label}
                                color={option.color}
                                disabled={disable}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>

                {studentAttendance[singleStudent?.student.baseUser?.id]
                    ?.attendanceStatus == "late" && (
                    <TextField
                        style={{ minWidth: "220px" }}
                        size="small"
                        name="lateDate"
                        label="Late Date"
                        value={
                            studentAttendance[singleStudent.student.baseUser.id]
                                ?.lateDate
                        }
                        onChange={(ev) =>
                            handleChange(ev, singleStudent.student.baseUser.id)
                        }
                        variant="outlined"
                        key={singleStudent.student.baseUser.id}
                        sx={{ mt: "5px" }}
                        type={"datetime-local"}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        disabled={disable}
                    />
                )}
            </TableCell>
        </TableRow>
    );
};

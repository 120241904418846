import { ArrowDownward, ArrowUpward, ClearAll } from "@mui/icons-material";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import BaseAccordian from "../../../BaseAccordian";
import { MultiPermissionAuthorize } from "../../../MultiPermissionAuthorize";
import { CampusService } from "../../../services/CampusService";
import AddView from "../../AddView";
import { AppContext } from "../../AppContext";
import { Search } from "../../Filters/Search";
import { RUDTable } from "../../Table/RUDTable";
import FeedbackSnackbar from "../../TestResult/FeedBackSnackBar";
import AddCampus from "./AddCampus";
import CustomFields from "./CustomFields";
const Campus = () => {
    const [filter, setFilter] = useState<any>({ search: "", apply: 0 });
    const [changes, setChanges] = useState(0);
    const { user, feedback, setFeedback } = useContext(AppContext);
    const [showWithDelay, setShowWithDelay] = useState(false);

    const [clearFilters, setClearFilters] = useState(false);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({ search: "" });
    };

    const ChangeCampusSequence = ({ row, setChangesCount }: any) => {
        const handleLevelChange = async (direction: string) => {
            const [data, err] = await CampusService.changeLevel(
                row.id,
                direction
            );

            if (data) {
                setChanges((c: number) => c + 1);
            }
        };

        return (
            <div>
                <IconButton
                    onClick={() => handleLevelChange("up")}
                    size="small"
                >
                    <Tooltip title="Move up">
                        <ArrowUpward fontSize="small" />
                    </Tooltip>
                </IconButton>

                <IconButton
                    onClick={() => handleLevelChange("down")}
                    size="small"
                >
                    <Tooltip title="Move down">
                        <ArrowDownward fontSize="small" />
                    </Tooltip>
                </IconButton>
            </div>
        );
    };

    useEffect(() => {
        let timer: any;
        if (feedback.show) {
            // Start the delay timer
            timer = setTimeout(() => {
                setShowWithDelay(true);
            }, 500); // Adjust the delay time in milliseconds
        } else {
            // Reset visibility when feedback.show is false
            setShowWithDelay(false);
        }

        return () => clearTimeout(timer); // Clean up the timer
    }, [feedback.show]);

    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Campus
                    </Typography>
                </Grid>

                <Grid item>
                    <MultiPermissionAuthorize ops={["CREATE CAMPUS"]}>
                        <AddView
                            label="add campus"
                            triggerRowsRefetch={() => setChanges(changes + 1)}
                        >
                            <AddCampus />
                        </AddView>
                    </MultiPermissionAuthorize>
                </Grid>
            </Grid>

            <BaseAccordian>
                <Grid item container spacing={2}>
                    <Grid item xs={12} md={4}>
                        {" "}
                        <Search
                            filter={filter}
                            setFilter={setFilter}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Button
                            color="primary"
                            fullWidth
                            variant="outlined"
                            onClick={() =>
                                setFilter({
                                    ...filter,
                                    apply: filter.apply + 1,
                                })
                            }
                            style={{ height: "100%" }}
                            size="small"
                        >
                            {" "}
                            search
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Tooltip title="Clear Filters">
                            <IconButton
                                onClick={handleFilterClear}
                                color="primary"
                            >
                                <ClearAll />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </BaseAccordian>

            <RUDTable
                getter={(page, limit) =>
                    CampusService.getCampus(page, limit, {
                        search: filter.search ? filter.search : undefined,
                    })
                }
                deletor={
                    user?.baseUser?.ops.includes("DELETE CAMPUS")
                        ? (id: string | number) =>
                              CampusService.deleteCampusSoft(id)
                        : undefined
                }
                // (id: string | number) => CampusService.deleteCampusSoft(id)
                rowsPreprocessor={(row) => ({
                    ...row,
                    deletionId: row.id,
                    latitude: row.latitude ? (
                        row.latitude
                    ) : (
                        <HorizontalRuleIcon />
                    ),

                    longitude: row.longitude ? (
                        row.longitude
                    ) : (
                        <HorizontalRuleIcon />
                    ),

                    Editlatitude: row.latitude ? row.latitude : "",
                    Editlongitude: row.longitude ? row.longitude : "",
                })}
                readables={{
                    id: "ID",
                    name: "Name",
                    address: "Address",
                    startTime: "Start time",
                    endTime: "End time",
                    latitude: "latitude",
                    longitude: "Longitude",
                    CampusAddInfo: "Campus AddInfo",
                    Edit: "Edit",
                    Changesequence: "Change Sequence",
                    // Editlatitude: "Editlatitude",
                }}
                editables={[]}
                customCols={[
                    {
                        header: "Changesequence",
                        content: (row) => (
                            <ChangeCampusSequence
                                row={row}
                                setChangesCount={setChanges}
                            />
                        ),
                    },
                    {
                        header: "CampusAddInfo",
                        content: (row) => <CustomFields row={row} />,
                    },
                    {
                        header: user?.baseUser?.ops.includes("UPDATE CAMPUS")
                            ? "Edit"
                            : "",
                        content: (row) => (
                            <MultiPermissionAuthorize ops={["UPDATE CAMPUS"]}>
                                <AddView
                                    label="Edit campus"
                                    updateMode
                                    triggerRowsRefetch={() =>
                                        setChanges(changes + 1)
                                    }
                                >
                                    <AddCampus row={row} updateMode />
                                </AddView>
                            </MultiPermissionAuthorize>
                        ),
                    },
                ]}
                updatingAgents={[filter.apply, changes]}
                ops={{
                    read: "READ CAMPUS",
                    edit: "",
                    delete: "DELETE CAMPUS",
                }}
                storageKey="campus"
            />

            {showWithDelay && (
                <FeedbackSnackbar
                    feedback={feedback}
                    setFeedback={setFeedback}
                />
            )}
        </div>
    );
};

export default Campus;

import {
    ArrowDownward,
    ArrowUpward,
    Circle,
    ClearAll,
    ToggleOff,
} from "@mui/icons-material";
import {
    Button,
    Chip,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import BaseAccordian from "../../../BaseAccordian";
import { MultiPermissionAuthorize } from "../../../MultiPermissionAuthorize";
import { SectionServices } from "../../../services/SectionServices";
import AddView from "../../AddView";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";
import { Search } from "../../Filters/Search";
import { RUDTable } from "../../Table/RUDTable";
import FeedbackSnackbar from "../../TestResult/FeedBackSnackBar";
import AddSection from "./AddSection";
import { EditSection } from "./EditSection";

export function Section() {
    const [showWithDelay, setShowWithDelay] = useState(false);
    const { user, feedback, setFeedback } = useContext(AppContext);
    const [filter, setFilter] = useState<any>({
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        classroom: "",
        status: "",
        apply: "",
        search: "",
    });
    const [changes, setChanges] = useState(0);

    const [clearFilters, setClearFilters] = useState(false);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            campus:
                user?.baseUser?.userRegions?.length > 1
                    ? ""
                    : user?.baseUser?.userRegions[0]?.campus?.id,
            classroom: "",
            apply: "",
            status: "",
        });
    };

    // useEffect(() => {
    //     if (filter.classroom === "all") {
    //         setFilter({ ...filter, classroom: undefined });
    //     }
    // }, [filter.classroom]);

    const ChangeSectionSequence = ({ row, setChangesCount }: any) => {
        const handleLevelChange = async (direction: string) => {
            const [data, err] = await SectionServices.changeLevel(
                row.id,
                direction
            );

            if (data) {
                setChanges((c: number) => c + 1);
            }
        };

        return (
            <div>
                <IconButton
                    onClick={() => handleLevelChange("up")}
                    size="small"
                >
                    <Tooltip title="Move up">
                        <ArrowUpward fontSize="small" />
                    </Tooltip>
                </IconButton>

                <IconButton
                    onClick={() => handleLevelChange("down")}
                    size="small"
                >
                    <Tooltip title="Move down">
                        <ArrowDownward fontSize="small" />
                    </Tooltip>
                </IconButton>
            </div>
        );
    };

    useEffect(() => {
        let timer: any;
        if (feedback.show) {
            // Start the delay timer
            timer = setTimeout(() => {
                setShowWithDelay(true);
            }, 500); // Adjust the delay time in milliseconds
        } else {
            // Reset visibility when feedback.show is false
            setShowWithDelay(false);
        }

        return () => clearTimeout(timer); // Clean up the timer
    }, [feedback.show]);

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="h4" color="primary" gutterBottom>
                    Section
                </Typography>

                <MultiPermissionAuthorize ops={["CREATE SECTION"]}>
                    <AddView
                        label="add section"
                        triggerRowsRefetch={() => setChanges(changes + 1)}
                    >
                        <AddSection />
                    </AddView>
                </MultiPermissionAuthorize>
            </div>

            <BaseAccordian>
                <Grid container spacing={1.5}>
                    {/* <Grid item xs={12} sm={6} md={3}>
                        <Autocomplete
                            api="/org/campus"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    campus: c?.id || "",
                                })
                            }
                            label="Campus"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                        />
                    </Grid> */}
                    {user?.baseUser?.userRegions?.length > 1 && (
                        <Grid item xs={12} md={6} lg={3}>
                            <Autocomplete
                                api="/org/campus"
                                setOutput={(c: any) =>
                                    setFilter({
                                        ...filter,
                                        campus: c?.id || "",
                                    })
                                }
                                label="Campus"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                }}
                                clear={clearFilters}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} sm={6} md={3}>
                        <Autocomplete
                            api="/org/academics/classroom"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    classroom: c?.id || "",
                                })
                            }
                            label="Class"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                            apiParams={{
                                campus: filter.campus,
                                status: "active",
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Autocomplete
                            setOutput={(c) =>
                                setFilter({
                                    ...filter,
                                    status: c?.id || "",
                                })
                            }
                            label="Status"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            defaultOptions={[
                                { id: "active", name: "Active" },
                                { id: "inactive", name: "Inactive" },
                            ]}
                            clear={clearFilters}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Search
                            filter={filter}
                            setFilter={setFilter}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Button
                            color="primary"
                            fullWidth
                            variant="contained"
                            onClick={() =>
                                setFilter({
                                    ...filter,
                                    apply: filter.apply + 1,
                                })
                            }
                            style={{ height: "100%" }}
                            size="small"
                        >
                            {" "}
                            Search
                        </Button>
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Tooltip title="Clear Filters">
                            <IconButton
                                onClick={handleFilterClear}
                                color="primary"
                            >
                                <ClearAll />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </BaseAccordian>

            <RUDTable
                getter={(page, limit) =>
                    SectionServices.getSections(page, limit, {
                        campus: filter.campus ? filter.campus : undefined,
                        status: filter.status ? filter.status : undefined,
                        classroom: filter.classroom
                            ? filter.classroom
                            : undefined,

                        search: filter.search ? filter.search : undefined,
                    })
                }
                editor={SectionServices.editSection}
                deletor={
                    user?.baseUser?.ops.includes("DELETE SECTION")
                        ? (id: string | number) =>
                              SectionServices.deleteSectionSoft(id)
                        : undefined
                }
                rowsPreprocessor={(row) => ({
                    ...row,
                    deletionId: row.id,
                    classroomName: row.classroom.name,
                    educationalCategory: row.classroom.educationalCategory,
                    campusName: row.classroom.campus.name,
                    _status: (
                        <Chip
                            variant="outlined"
                            icon={
                                row.status === "active" ? (
                                    <Circle color="success" fontSize="small" />
                                ) : (
                                    <ToggleOff fontSize="small" color="error" />
                                )
                            }
                            label={row.status}
                            sx={{ textTransform: "capitalize" }}
                            color={
                                row.status === "active" ? "success" : "error"
                            }
                            size="small"
                        />
                    ),
                })}
                readables={{
                    id: "ID",
                    name: "Name",
                    classroomName: "Class",
                    educationalCategory: "Educational Category",
                    campusName: "Campus",
                    _status: "Status",
                    Edit: "Edit",
                    Changesequence: "Change Sequence",
                }}
                customCols={[
                    {
                        header: "Changesequence",
                        content: (row) => (
                            <ChangeSectionSequence
                                row={row}
                                setChangesCount={setChanges}
                            />
                        ),
                    },
                    {
                        header: user?.baseUser?.ops.includes("UPDATE SECTION")
                            ? "Edit"
                            : "",
                        content: (row) => (
                            <MultiPermissionAuthorize ops={["UPDATE SECTION"]}>
                                <AddView
                                    label="Edit section"
                                    updateMode
                                    triggerRowsRefetch={() =>
                                        setChanges(changes + 1)
                                    }
                                >
                                    <EditSection row={row} updateMode />
                                </AddView>
                            </MultiPermissionAuthorize>
                        ),
                    },
                ]}
                updatingAgents={[filter.apply, changes]}
                ops={{
                    read: "READ SECTION",
                    edit: "",
                    delete: "DELETE SECTION",
                }}
                storageKey="section"
            />

            {showWithDelay && (
                <FeedbackSnackbar
                    feedback={feedback}
                    setFeedback={setFeedback}
                />
            )}
        </div>
    );
}

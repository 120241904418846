import { Box, SxProps, TextField, Theme } from "@mui/material";
import {
    ChangeEvent,
    Dispatch,
    FormEvent,
    SetStateAction,
    useEffect,
    useState,
} from "react";

type Props = {
    filter: any;
    setFilter: Dispatch<SetStateAction<any>>;
    sx?: SxProps<Theme>;
    textFieldProps?: any;
    btnProps?: any;
    clear?: Boolean;
};

export const Search = ({
    filter,
    setFilter,
    sx,
    textFieldProps,
    btnProps,
    clear,
}: Props) => {
    const [searchTerm, setSearchTerm] = useState("");

    const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(ev.target.value);
    };

    const handleSearch = (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        setFilter({ ...filter, search: searchTerm });
    };

    useEffect(() => {
        setFilter({ ...filter, search: "" });
        setSearchTerm("");
    }, [clear]);

    return (
        <Box
            component="form"
            onSubmit={handleSearch}
            sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                ...sx,
            }}
        >
            <TextField
                placeholder="Search here..."
                fullWidth
                name="search"
                variant="outlined"
                value={filter.search}
                onChange={(ev) =>
                    setFilter({ ...filter, search: ev.target.value })
                }
                size="small"
                {...textFieldProps}
            />
            {/* <Button
                variant="outlined"
                color="primary"
                type="submit"
                {...btnProps}
                style={{ height: "100%" }}
            >
                Search
            </Button> */}
        </Box>
    );
};

import { Image } from "@mui/icons-material";
import { Grow, IconButton, Modal, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { S3Service } from "../../../services/S3Service";
import { awsDirStruct } from "../../constants";

type Props = {
    id: string;
    simpleView?: boolean;
    objectFit?: string;
};

const StudentApplicationPreview = ({
    id,
    simpleView,
    objectFit = "contain",
}: Props) => {
    const [open, setOpen] = useState(simpleView ? simpleView : false);
    const [fetchImage, setFetch] = useState(false);
    const [pic, setPic] = useState<string | null>(null);
    const [imageError, setImageError] = useState(false); // Track broken images
    const theme = useTheme();

    const getImage = async () => {
        const key = `${awsDirStruct.attendance.leaveImages}/${id}`;
        const [data, err] = await S3Service.getS3ByKey(key);

        if (data) {
            setPic(data.url);
            setImageError(false); // Reset error state if image loads
        } else {
            setPic(null); // No image found
        }
    };

    useEffect(() => {
        getImage();
    }, []);

    const renderContent = () => {
        if (imageError || !pic) {
            return <div>No image uploaded</div>;
        }

        return (
            <img
                src={pic}
                alt="Uploaded"
                style={{
                    objectFit: "cover",
                    height: "90%",
                    margin: "auto 0",
                }}
                onError={() => setImageError(true)} // Handle image load errors
            />
        );
    };

    return (
        <>
            {!simpleView && (
                <IconButton
                    onClick={() => {
                        setOpen(true);
                        setFetch(true);
                    }}
                    size="small"
                >
                    <Image fontSize="small" />
                </IconButton>
            )}

            {simpleView ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backdropFilter: "blur(4px)",
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {renderContent()}
                    </div>
                </div>
            ) : (
                <Modal
                    open={open}
                    onClose={() => setOpen(false)}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backdropFilter: "blur(4px)",
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Grow in={open}>{renderContent()}</Grow>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default StudentApplicationPreview;

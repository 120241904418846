import { Close } from "@mui/icons-material";
import {
    Alert,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { usePostAPI } from "../../../services/Hooks";
import { SessionService } from "../../../services/SessionService";
import { StudentAttendanceService } from "../../../services/StudentAttendanceService";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";
import { baseAPI } from "../../constants";
import FeedbackSnackbar from "../../TestResult/FeedBackSnackBar";
import { UpdateStudentAttendanceRow } from "./UpdateStudentAttendanceRow";

const UpdateStudentAttendanceBulk = () => {
    const theme = useTheme();

    const attendanceOptions = [
        {
            label: "Present",
            status: "present",
            id: "present",
            color: theme.palette.success.main,
        },
        {
            label: "Absent",
            status: "absent",
            id: "absent",
            color: theme.palette.error.main,
        },
        { label: "Leave", status: "leave", id: "leave", color: "#fdd835" },
        {
            label: "Late",
            status: "late",
            id: "late",
            color: theme.palette.warning.main,
        },
        {
            label: "Off Day",
            status: "offDay",
            id: "offDay",
            color: theme.palette.info.main,
        },

        {
            label: "Sick Leave",
            status: "sickleave",
            id: "sickLeave",
            color: theme.palette.secondary.main,
        },
    ];
    const { user, feedback, setFeedback } = useContext(AppContext);

    const [lastSession, setLastSession] = useState<any>();
    const history = useHistory();
    const [students, setStudents] = useState<any>();
    const [pagination, setPagination] = useState({
        page: 0,
        count: 0,
        limit: 100,
    });
    const [filter, setFilter] = useState<any>({
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        session: "",
        classroom: "",
        section: "",
        status: "",
        admissionStatus: "",
        apply: 0,
    });

    const [attendanceList, setAttendanceList] = useState<any>();

    const [studentAttendanceInfo, setStudentAttendanceInfo] = useState({
        attendanceDate: new Date().toISOString().split("T")[0],
        subject: "",
        user: user?.baseUser.id,
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        classroom: "",
        type: "",
        session: "",
        section: "",
    });
    const [showResponseDetails, setShowResponseDetails] = useState(false);

    const [showColumns, setShowColumns] = useState(false);
    const [studentAttendance, setStudentAttendance] = useState<{
        [key: number]: any;
    }>({});

    const [studentAttendanceCopy, setStudentAttendanceCopy] = useState<{
        [key: number]: any;
    }>({});

    const isAllChecked = useMemo(() => {
        const { page, limit } = pagination;

        const start = page * limit;
        const end = (page + 1) * limit - 1;

        const checked = Object.values(studentAttendance)
            .slice(start, end)
            .every((x) => x.checked);

        return checked;
    }, [studentAttendance]);

    const isSomeChecked = useMemo(() => {
        const { page, limit } = pagination;

        const start = page * limit;
        const end = (page + 1) * limit - 1;

        const checked =
            !isAllChecked &&
            Object.values(studentAttendance)
                .slice(start, end)
                .some((x) => x.checked);

        return checked;
    }, [studentAttendance]);

    function handleAllCheck(
        e: ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) {
        setStudentAttendance((curr) => {
            const allChecked = Object.fromEntries(
                Object.entries(curr).map(([k, v], index) => {
                    const { page, limit } = pagination;

                    const start = page * limit;
                    const end = (page + 1) * limit;

                    if (index >= start && index < end) {
                        return [
                            k,
                            {
                                ...v,
                                checked,
                            },
                        ];
                    }

                    return [k, v];
                })
            );

            return allChecked;
        });
    }

    const [clearFilters, setClearFilters] = useState(false);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            campus: "",
            session: "",
            classroom: "",
            section: "",
            status: "",
            admissionStatus: "",
            apply: 0,
        });
    };

    const getAttendance = async () => {
        const [data, err] = await StudentAttendanceService.getAttendance(
            pagination.page + 1,
            pagination.limit,
            {
                campus: studentAttendanceInfo.campus,
                classroom: studentAttendanceInfo.classroom,
                section: studentAttendanceInfo.section,
                session: studentAttendanceInfo.session,
                type: studentAttendanceInfo.type,
                subject: studentAttendanceInfo.subject,
                attendanceDate: studentAttendanceInfo.attendanceDate,
            }
        );

        if (data?.rows?.length) {
            setAttendanceList(data.rows);
            setStudentAttendance(
                data.rows.reduce((prev: any, curr: any) => {
                    const date = new Date(curr.lateDate);
                    const formattedTime =
                        date.getHours().toString().padStart(2, "0") +
                        ":" +
                        date.getMinutes().toString().padStart(2, "0");

                    const formattedDate =
                        date.toJSON().split("T")[0] + "T" + formattedTime;

                    return {
                        ...prev,
                        [curr.student.baseUser.id]: {
                            // baseUserId: curr.student.baseUser.id,
                            attendanceStatus: curr.attendanceStatus,
                            student: curr.student.id,
                            id: curr.id,
                            checked: true,
                            lateDate: formattedDate,
                        },
                    };
                }, {})
            );
            setStudentAttendanceCopy(
                data.rows.reduce((prev: any, curr: any) => {
                    const date = new Date(curr.lateDate);
                    const formattedTime =
                        date.getHours().toString().padStart(2, "0") +
                        ":" +
                        date.getMinutes().toString().padStart(2, "0");

                    const formattedDate =
                        date.toJSON().split("T")[0] + "T" + formattedTime;

                    return {
                        ...prev,
                        [curr.student.baseUser.id]: {
                            // baseUserId: curr.student.baseUser.id,
                            attendanceStatus: curr.attendanceStatus,
                            student: curr.student.id,
                            id: curr.id,
                            checked: true,
                            lateDate: formattedDate,
                        },
                    };
                }, {})
            );
            setPagination({
                ...pagination,
                count: data?.count ?? 0,
            });
        } else {
            setAttendanceList([]);
            setStudentAttendance({});
        }
    };

    const [updateStudentAttendance, response, status, message] = usePostAPI(
        `${baseAPI}/org/attandance/update-bulk`
    );

    async function handleSubmit(ev: any) {
        ev.preventDefault();
        setFeedback({ ...feedback, loading: true });
        const checkedResults = Object.values(studentAttendance).filter(
            (student) => student.checked && student.id
        );

        const allHaveAttendanceStatus = Object.values(studentAttendance)?.every(
            (x) => Boolean(x.attendanceStatus)
        );

        if (!checkedResults || checkedResults.length == 0) {
            setFeedback({
                loading: false,
                message:
                    "You must checked a row in order to take an attendance",
                show: true,
                severity: "error",
            });
        } else if (allHaveAttendanceStatus == false) {
            setFeedback({
                loading: false,
                message: "Must provide attendance status for each student",
                show: true,
                severity: "error",
            });

            return;
        } else if (studentAttendanceInfo.attendanceDate == "") {
            setFeedback({
                loading: false,
                message:
                    "Must provide attendace date in order to take attendace",
                show: true,
                severity: "error",
            });
        } else if (studentAttendanceInfo.user == "") {
            setFeedback({
                loading: false,
                message: "Must provide a user in order to take attendance",
                show: true,
                severity: "error",
            });
        } else if (
            studentAttendanceInfo.type == "subject" &&
            studentAttendanceInfo.subject == ""
        ) {
            setFeedback({
                loading: false,
                message:
                    "Must provide a subject in order to take attendance subjectWise",
                show: true,
                severity: "error",
            });
        } else if (studentAttendanceInfo.section == "") {
            setFeedback({
                loading: false,
                message: "Must provide a section in order to take attendance",
                show: true,
                severity: "error",
            });
        } else if (studentAttendanceInfo.type == "") {
            setFeedback({
                loading: false,
                message:
                    "Must provide attendance type in order to take attendance",
                show: true,
                severity: "error",
            });
        } else {
            const hasChanges =
                JSON.stringify(studentAttendance) !==
                JSON.stringify(studentAttendanceCopy);

            if (!hasChanges) {
                setFeedback({
                    loading: false,
                    message:
                        "Cannot update attandance when there is no change.",
                    severity: "error",
                    show: true,
                });
                return;
            }
            updateStudentAttendance(
                {
                    ...studentAttendanceInfo,
                    attendanceList: checkedResults,
                },
                {}
            );
        }
    }

    useEffect(() => {
        if (
            studentAttendanceInfo.campus &&
            studentAttendanceInfo.session &&
            studentAttendanceInfo.classroom &&
            studentAttendanceInfo.attendanceDate &&
            studentAttendanceInfo.type
        ) {
            getAttendance();
        }
    }, [
        filter.search,
        pagination.count,
        pagination.page,
        pagination.limit,
        studentAttendanceInfo.session,
        studentAttendanceInfo.classroom,
        studentAttendanceInfo.section,
        studentAttendanceInfo.attendanceDate,
        studentAttendanceInfo.type,
    ]);

    useEffect(() => {
        if (studentAttendanceInfo.attendanceDate) {
            getAttendance();
        }
    }, [studentAttendanceInfo.attendanceDate]);

    useEffect(() => {
        if (studentAttendanceInfo.subject) {
            getAttendance();
        }
    }, [studentAttendanceInfo.subject]);

    const LastSession = async () => {
        try {
            const [data, err] = await SessionService.getLastSession(1, 1, {
                campus: studentAttendanceInfo.campus
                    ? studentAttendanceInfo.campus
                    : null,
            });

            if (err) {
                console.error("Error fetching last session:", err);
                return;
            }

            if (data && data.rows.length) {
                setStudentAttendanceInfo((prevData: any) => ({
                    ...prevData,
                    session: data.rows[0].id,
                }));
                setLastSession(data.rows[0]);
            } else {
                console.warn("No session data found.");
            }
        } catch (error) {
            console.error("Unexpected error in LastSession:", error);
        }
    };

    useEffect(() => {
        if (studentAttendanceInfo.campus) {
            LastSession();
        }
    }, [studentAttendanceInfo?.campus as any]);

    useEffect(() => {
        if (["idle", "loading"].includes(status)) return;

        setFeedback({
            ...feedback,
            respneseDetail: response,
            show: true,
            severity: status == "success" ? "success" : "error",
            message: message,
        });

        if (status == "success") {
            history.replace("/attendance");
        }
    }, [status, message, response]);

    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return; // Prevent closing on clickaway if needed
        }

        setFeedback({
            message: "",
            show: false,
            severity: "" as any,
            loading: false,
            respneseDetail: [],
        });
    };

    return (
        <div>
            <Typography variant="h4" color={"primary"}>
                Update Student Attendance
            </Typography>
            <Grid item xs={12}>
                <Snackbar
                    open={feedback.show}
                    // autoHideDuration={6000}
                    onClose={handleClose} // Close handler
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    message="Message"
                >
                    <Alert
                        severity={feedback.severity as any}
                        action={
                            <Button
                                onClick={handleClose}
                                color="inherit"
                                size="small"
                            >
                                <Close />
                            </Button>
                        }
                    >
                        {feedback.message}
                    </Alert>
                </Snackbar>
            </Grid>

            <div>
                <Grid container style={{ paddingTop: "2rem" }} spacing={1}>
                    {user?.baseUser?.userRegions?.length > 1 && (
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                api="/org/campus"
                                setOutput={(c: any) =>
                                    setStudentAttendanceInfo({
                                        ...studentAttendanceInfo,
                                        campus: c?.id || "",
                                    })
                                }
                                label="Campus"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                    required: true,
                                }}
                                clear={clearFilters}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            api="/org/session"
                            setOutput={(c: any) =>
                                setStudentAttendanceInfo({
                                    ...studentAttendanceInfo,
                                    session: c.id || "",
                                })
                            }
                            label="Session"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                                required: true,
                            }}
                            clear={clearFilters}
                            apiParams={{
                                campus: studentAttendanceInfo.campus,
                                status: "active",
                            }}
                            defaultValue={lastSession}
                            key={lastSession?.id}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            api="/org/academics/classroom"
                            setOutput={(c: any) =>
                                setStudentAttendanceInfo({
                                    ...studentAttendanceInfo,
                                    classroom: c?.id || "",
                                })
                            }
                            label="Class"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                                required: true,
                            }}
                            clear={clearFilters}
                            apiParams={{
                                campus: studentAttendanceInfo.campus,
                                status: "active",
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            api="/org/academics/section"
                            setOutput={(c: any) =>
                                setStudentAttendanceInfo({
                                    ...studentAttendanceInfo,
                                    section: c.id || "",
                                })
                            }
                            label="Section"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                                required: true,
                            }}
                            clear={clearFilters}
                            apiParams={{
                                classroom: studentAttendanceInfo.classroom,
                                status: "active",
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            defaultOptions={[
                                {
                                    label: "Attendance By Day",
                                    status: "dayWise",
                                    id: "dayWise",
                                },
                                {
                                    label: "Attendance By Subject",
                                    status: "subject",
                                    id: "subject",
                                },
                            ]}
                            setOutput={(c: any) =>
                                setStudentAttendanceInfo({
                                    ...studentAttendanceInfo,
                                    type: c?.id || "",
                                })
                            }
                            label="Attendance Type"
                            labelKey="label"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                                required: true,
                            }}
                            clear={clearFilters}
                        />
                    </Grid>

                    {studentAttendanceInfo?.type == "subject" && (
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                api="/org/Academics/Subject"
                                setOutput={(c: any) =>
                                    setStudentAttendanceInfo({
                                        ...studentAttendanceInfo,
                                        subject: c?.id || "",
                                    })
                                }
                                label="Subject"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                }}
                                clear={clearFilters}
                                apiParams={{
                                    classroom: studentAttendanceInfo.classroom,
                                    status: "active",
                                }}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} md={4}>
                        <TextField
                            size="small"
                            label="Attendance Date"
                            fullWidth
                            value={studentAttendanceInfo.attendanceDate}
                            name="date"
                            onChange={(ev) => {
                                const selectedDate = ev.target.value;
                                const currentDate = new Date()
                                    .toISOString()
                                    .split("T")[0];

                                if (selectedDate <= currentDate) {
                                    setStudentAttendanceInfo({
                                        ...studentAttendanceInfo,
                                        attendanceDate: selectedDate,
                                    });
                                }
                            }}
                            required
                            type="date"
                            InputProps={{
                                inputProps: {
                                    max: new Date().toISOString().split("T")[0], // Set max attribute to today
                                },
                            }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <TablePagination
                        component="div"
                        onPageChange={(ev, page) =>
                            setPagination({ ...pagination, page: page })
                        }
                        onRowsPerPageChange={(ev) =>
                            setPagination({
                                ...pagination,
                                limit: parseInt(ev.target.value),
                                page: 0,
                            })
                        }
                        count={pagination.count}
                        page={pagination.page}
                        rowsPerPage={pagination.limit}
                        rowsPerPageOptions={[100, 250, 500]}
                    />
                </Grid>

                {studentAttendanceInfo.campus == "" ||
                studentAttendanceInfo.session == "" ||
                studentAttendanceInfo.classroom == "" ||
                studentAttendanceInfo.section == "" ||
                studentAttendanceInfo.type == "" ||
                studentAttendanceInfo.attendanceDate == "" ? (
                    <Alert severity="info">
                        Please select a
                        campus,session,class,section,attendanceType and
                        attendanceDate
                    </Alert>
                ) : Object.values(studentAttendance).length == 0 ? (
                    <Alert severity="info" style={{ width: "100%" }}>
                        No attendance found
                    </Alert>
                ) : (
                    <form
                        onSubmit={(ev) => {
                            handleSubmit(ev);
                        }}
                        style={{ paddingTop: "3rem" }}
                    >
                        <Grid item xs={12}>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow
                                            style={{ whiteSpace: "nowrap" }}
                                        >
                                            <TableCell>
                                                <FormControl>
                                                    <Checkbox
                                                        checked={isAllChecked}
                                                        onChange={
                                                            handleAllCheck
                                                        }
                                                        indeterminate={
                                                            isSomeChecked
                                                        }
                                                    />
                                                </FormControl>
                                            </TableCell>
                                            <TableCell>Sr No.</TableCell>
                                            <TableCell>Reg No.</TableCell>
                                            <TableCell>Enroll No</TableCell>
                                            <TableCell>File No</TableCell>
                                            <TableCell>Id</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Father Name</TableCell>

                                            <TableCell>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">
                                                        Attendance Status
                                                    </FormLabel>
                                                    <RadioGroup
                                                        row
                                                        onChange={(event) => {
                                                            const selectedId =
                                                                event.target
                                                                    .value;
                                                            setStudentAttendance(
                                                                Object.fromEntries(
                                                                    Object.entries(
                                                                        studentAttendance
                                                                    ).map(
                                                                        ([
                                                                            k,
                                                                            v,
                                                                        ]) => [
                                                                            k,
                                                                            {
                                                                                ...v,
                                                                                attendanceStatus:
                                                                                    selectedId ??
                                                                                    "",
                                                                                checked:
                                                                                    true,
                                                                            },
                                                                        ]
                                                                    )
                                                                )
                                                            );
                                                        }}
                                                    >
                                                        {attendanceOptions.map(
                                                            (option) => (
                                                                <FormControlLabel
                                                                    key={
                                                                        option.id
                                                                    }
                                                                    value={
                                                                        option.id
                                                                    }
                                                                    control={
                                                                        <Radio
                                                                            style={{
                                                                                color: `${option.color}`,
                                                                            }}
                                                                        />
                                                                    }
                                                                    label={
                                                                        option.label
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </RadioGroup>
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {attendanceList &&
                                            attendanceList?.map(
                                                (coll: any, id: number) => (
                                                    <UpdateStudentAttendanceRow
                                                        count={
                                                            id +
                                                            1 +
                                                            pagination.limit *
                                                                pagination.page
                                                        }
                                                        key={id}
                                                        singleStudent={coll}
                                                        studentAttendance={
                                                            studentAttendance
                                                        }
                                                        setStudentAttendance={
                                                            setStudentAttendance
                                                        }
                                                        checkAll={isAllChecked}
                                                        id={id + 1}
                                                    />
                                                )
                                            )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <div
                            style={{
                                paddingTop: "1rem",
                            }}
                        >
                            <Button
                                color="primary"
                                variant="outlined"
                                disabled={status === "loading"}
                                fullWidth
                                size="large"
                                type="submit"
                                endIcon={
                                    status === "loading" ? (
                                        <CircularProgress size="1rem" />
                                    ) : null
                                }
                            >
                                {status === "loading"
                                    ? "Updating Attendance Result..."
                                    : "Update  Attendance"}
                            </Button>
                        </div>
                    </form>
                )}
            </div>

            <FeedbackSnackbar feedback={feedback} setFeedback={setFeedback} />
        </div>
    );
};

export default UpdateStudentAttendanceBulk;

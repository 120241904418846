import { Edit } from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    TextField,
    useTheme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { StudentAttendanceService } from "../../../services/StudentAttendanceService";
import { AppContext } from "../../AppContext";

const EditSingleAttendance = ({
    row,
    onAttendanceChange,
    changes,
    setChanges,
}: any) => {
    const { feedback, setFeedback } = useContext(AppContext);
    const [studentAttendance, setStudentAttendance] = useState<any>();
    const [studentAttendanceCopy, setStudentAttendanceCopy] = useState<any>();

    console.log(row);
    const theme = useTheme();

    const attendanceOptions = [
        {
            label: "Present",
            status: "present",
            id: "present",
            color: theme.palette.success.main,
        },
        {
            label: "Absent",
            status: "absent",
            id: "absent",
            color: theme.palette.error.main,
        },
        { label: "Leave", status: "leave", id: "leave", color: "#fdd835" },
        {
            label: "Late",
            status: "late",
            id: "late",
            color: theme.palette.warning.main,
        },
        {
            label: "Off Day",
            status: "offDay",
            id: "offDay",
            color: theme.palette.info.main,
        },
        {
            label: "Sick Leave",
            status: "sickleave",
            id: "sickLeave",
            color: theme.palette.secondary.main,
        },
    ];

    const [open, setOpen] = useState<boolean>(false);

    function getTodayDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const day = String(today.getDate()).padStart(2, "0");
        const hours = String(today.getHours()).padStart(2, "0");
        const minutes = String(today.getMinutes()).padStart(2, "0");

        return `${year}-${month}-${day}T${hours}:${minutes}`;
    }

    // Update studentAttendance when row changes
    useEffect(() => {
        if (row?.attendanceStatus) {
            const date = new Date(row._lateDate);
            const formattedTime =
                date.getHours().toString().padStart(2, "0") +
                ":" +
                date.getMinutes().toString().padStart(2, "0");

            const formattedDate =
                date.toJSON().split("T")[0] + "T" + formattedTime;
            setStudentAttendance({
                attendanceStatus: row?._attendanceStatus,
                lateDate: formattedDate,
            });
        }
    }, [row]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedStatus = event.target.value;
        setStudentAttendance({
            ...studentAttendance,
            attendanceStatus: selectedStatus,
        });

        // Call the onAttendanceChange callback if provided (to reflect the change outside the component)
        if (onAttendanceChange) {
            onAttendanceChange(selectedStatus);
        }
    };

    const handleSubmit = async (ev: any) => {
        ev.preventDefault();

        // Call your API service for updating attendance

        const hasChanges =
            JSON.stringify(studentAttendance) !==
            JSON.stringify(studentAttendanceCopy);

        if (!hasChanges) {
            console.log("it hits");
            setFeedback({
                loading: false,
                message: "Cannot update attendance when there is no change.",
                severity: "error",
                show: true,
            });
            return;
        }

        const [data, err] =
            await StudentAttendanceService.editSingleStudentAttendance(
                row.id,
                studentAttendance
            );

        if (data) {
            setFeedback({
                loading: false,
                message: data.message,
                severity: "success",
                show: true,
            });

            setOpen(false);
            setChanges(changes + 1);

            // setOpen(false); // Close dialog after successful submission
        } else {
            setFeedback({
                loading: false,
                message: err,
                severity: "error",
                show: true,
            });
        }
    };

    useEffect(() => {
        setStudentAttendance({
            attendanceStatus: row?._attendanceStatus ?? "",
            lateDate: row?._lateDate,
        });
        setStudentAttendanceCopy({
            attendanceStatus: row?._attendanceStatus ?? "",
            lateDate: row?._lateDate,
        });
    }, [row?.id]);

    return (
        <div>
            <IconButton size="small" onClick={() => setOpen(true)}>
                <Edit />
            </IconButton>

            <Dialog
                open={open}
                onClose={(e, reason) => {
                    if (reason === "backdropClick") return; // Prevent dialog closing on backdrop click
                }}
                fullWidth
            >
                {/* <Grid item xs={12} style={{ padding: "4px" }}>
                    {feedback.show && (
                        <Alert severity={feedback.severity}>
                            {feedback.message}
                        </Alert>
                    )}
                </Grid> */}

                {studentAttendance && (
                    <form onSubmit={handleSubmit}>
                        <DialogContent>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    row
                                    value={studentAttendance?.attendanceStatus}
                                    onChange={handleChange}
                                >
                                    {attendanceOptions.map((option) => (
                                        <FormControlLabel
                                            key={option.id}
                                            value={option.id}
                                            control={
                                                <Radio
                                                    style={{
                                                        color: `${option.color}`,
                                                    }}
                                                />
                                            }
                                            label={option.label}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>

                            {studentAttendance.attendanceStatus === "late" && (
                                <TextField
                                    label="Late Date"
                                    name="lateDate"
                                    value={studentAttendance.lateDate}
                                    type="datetime-local"
                                    onChange={(e) =>
                                        setStudentAttendance({
                                            ...studentAttendance,
                                            lateDate: e.target.value,
                                        })
                                    }
                                />
                            )}
                        </DialogContent>

                        <DialogActions>
                            <Button
                                variant="contained"
                                onClick={() => setOpen(false)}
                            >
                                Close
                            </Button>
                            <Button variant="outlined" type="submit">
                                Edit
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Dialog>
        </div>
    );
};

export default EditSingleAttendance;

import { Box, Grid, Paper, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CertificateService } from "../../services/CertificateService";
import { S3Service } from "../../services/S3Service";
import { awsDirStruct } from "../constants";
import { Certificate, Footer } from "./Certificates";
import { certificatesData } from "./CertificatesData";
import Menu from "./Controls/Menu";
import Academic from "./Variants/Academic";
import Achievement from "./Variants/Achievement";

type Props = { mode: "create" | "update" };

const CreateCertificate = ({ mode }: Props) => {
    const [logoPosition, setLogoPosition] = useState({
        position: "center",
        swapped: false,
    });
    const theme = useTheme();
    const [certificate, setCertificate] = useState<Certificate>(
        certificatesData.academic
    );
    const params = useParams<{ id: string }>();

    const renderCertificate = () => {
        switch (certificate.type) {
            case "academic":
                return (
                    <Academic
                        fields={certificate.fields}
                        logoPosition={logoPosition}
                    />
                );

            case "achievement":
                return <Achievement fields={certificate.fields} />;

            default:
                break;
        }
    };

    //

    async function getFooterImagesFromS3(
        images: Footer[],
        certificateId: number
    ) {
        const key = `${awsDirStruct.org.picture}/${certificateId}/footer/`;

        const promises = images.map((item) =>
            S3Service.getS3ByKey(key + item.id)
        );

        Promise.allSettled(promises).then((res) => {
            const urls: string[] = [];

            res.forEach((item) => {
                if (item.status === "fulfilled") {
                    console.log(item, "Image ");
                    urls.push(item.value[0].url);
                }
            });

            console.log(urls);

            setCertificate((c) => ({
                ...c,
                fields: {
                    ...c.fields,
                    footer: c.fields.footer
                        ? {
                              ...c.fields.footer,
                              fields: c.fields.footer?.fields.map(
                                  (item, index) => ({
                                      ...item,
                                      url: urls[index],
                                  })
                              ),
                          }
                        : undefined,
                },
            }));
        });
    }

    async function getCertificateById(certificateId: string) {
        const [data, err] = await CertificateService.getCertificates(1, 1, {
            id: certificateId,
        });

        if (err) {
            console.log(err);
        } else {
            const _certificate = data.rows[0];

            if (_certificate) {
                const footer: Footer[] =
                    JSON.parse(_certificate.content)?.footer?.fields ?? [];

                if (footer.filter((item) => item.type === "image").length > 0) {
                    getFooterImagesFromS3(
                        footer.filter((item) => item.type === "image"),
                        _certificate.id
                    );
                }

                console.log(_certificate.id);

                setCertificate({
                    fields: JSON.parse(_certificate.content),
                    thumbnail: "",
                    type: _certificate.type,
                    id: _certificate.id,
                    name: _certificate.name,
                });
            }
        }
    }

    useEffect(() => {
        if (mode === "update" && params.id) {
            getCertificateById(params.id);
        }
    }, [mode]);

    return (
        <Grid
            container
            style={{
                padding: theme.spacing(2, 1),
                minHeight: `calc(100vh - ${theme.spacing(8)}px)`,
            }}
        >
            <Grid
                item
                xs={8}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // border: "1px solid",
                }}
            >
                <Box
                    width={768}
                    borderRadius={theme.shape.borderRadius}
                    overflow="clip"
                >
                    {renderCertificate()}
                </Box>
            </Grid>

            <Grid
                item
                xs={4}
                component={Paper}
                variant="outlined"
                style={{
                    maxHeight: `calc(100vh - ${theme.spacing(12)}px)`,
                    overflowY: "auto",
                }}
                // className={classes.scrollBar}
            >
                <Menu
                    certificate={certificate}
                    setCertificate={setCertificate}
                    mode={mode}
                />
            </Grid>
        </Grid>
    );
};

export default CreateCertificate;

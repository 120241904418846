import { Button, CircularProgress, Grid, TextField } from "@mui/material";
import { FormEvent, useContext, useEffect, useState } from "react";
import { PublisherService } from "../../../services/PublisherService";
import { AddViewContext } from "../../AddView";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";

type Props = {
    row?: any;
    updateMode?: boolean;
};

const EditPublisher = ({ row, updateMode }: Props) => {
    const { open, setOpen, triggerRowsRefetch } = useContext(AddViewContext);
    const [publisher, setPublisher] = useState<any>({
        name: "",
        status: "",
    });

    const [publisherCopy, setPublisherCopy] = useState<any>({
        name: "",
        status: "",
    });
    const { feedback, setFeedback } = useContext(AppContext);

    const handleChange = (ev: any) => {
        const value = ev.target.value;
        setPublisher({
            ...publisher,
            [ev.target.name]: value,
        });
    };

    const handleSubmit = async (ev: FormEvent) => {
        ev.preventDefault();
        setFeedback({ ...feedback, loading: true });

        const hasChanges =
            JSON.stringify(publisher) !== JSON.stringify(publisherCopy);

        if (!hasChanges) {
            setFeedback({
                loading: false,
                message: "Cannot update publisher when there is no change.",
                severity: "error",
                show: true,
            });
            return;
        }

        const [data, err] = await PublisherService.editPublisher(
            row.id,
            publisher
        );
        if (data) {
            setFeedback({
                loading: false,
                message: data.message,
                severity: "success",
                show: true,
            });

            setOpen(false);
            triggerRowsRefetch();
        } else {
            setFeedback({
                loading: false,
                message: err,
                severity: "error",
                show: true,
            });
        }
    };

    useEffect(() => {
        if (updateMode) {
            setPublisher({
                name: row?.name,
                status: row.status,
            });
            setPublisherCopy({
                name: row?.name,
                status: row.status,
            });
        }
    }, [updateMode]);
    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={1.5}>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        label="Publisher Name"
                        value={publisher.name}
                        name="name"
                        size="small"
                        onChange={handleChange}
                        fullWidth
                        required
                    />
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        setOutput={(c) =>
                            setPublisher({
                                ...publisher,
                                status: c?.id || "",
                            })
                        }
                        label="Status"
                        labelKey="name"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                        defaultOptions={[
                            { id: "active", name: "Active" },
                            { id: "inactive", name: "Inactive" },
                        ]}
                        defaultValue={
                            updateMode
                                ? {
                                      id: row?.status,
                                      name: row?.status,
                                  }
                                : undefined
                        }
                    />
                </Grid>

                <Grid item>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={feedback.loading}
                        endIcon={
                            feedback.loading ? (
                                <CircularProgress size="1em" />
                            ) : null
                        }
                    >
                        {!updateMode
                            ? feedback.loading
                                ? "Adding publisher..."
                                : "Add publisher"
                            : feedback.loading
                            ? "updating publisher..."
                            : "update publisher"}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default EditPublisher;

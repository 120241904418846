import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class StudentAttendanceService {
    static url = `${baseAPI}/org/attandance`;

    static async getAttendance(page = 1, limit = 10, filter = {}) {
        try {
            const response = await axios.get(StudentAttendanceService.url, {
                params: { page, limit, ...filter },
                withCredentials: true,
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getLeaveStudent(page = 1, limit = 10, filter = {}) {
        try {
            const response = await axios.get(
                StudentAttendanceService.url + "/get-leave",
                {
                    params: { page, limit, ...filter },
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async createSingleStudentAttendance(data: any) {
        try {
            const response = await axios.post(
                StudentAttendanceService.url + "/create/single",
                data,
                {
                    withCredentials: true,
                }
            );
            // console.log(data);

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getAttendancePercentage(page = 1, limit = 10, filter = {}) {
        try {
            const response = await axios.get(
                StudentAttendanceService.url + "/attendance-percentage",
                {
                    params: { page, limit, ...filter },
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getOverAllAttendance(page = 1, limit = 10, filter = {}) {
        try {
            const response = await axios.get(
                StudentAttendanceService.url + "/attendance-overAll",
                {
                    params: { page, limit, ...filter },
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteStudentAttendance(id: string | number) {
        try {
            const response = await axios.delete(
                StudentAttendanceService.url + "/delete-attendance",
                {
                    params: { id },
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async editSingleStudentAttendance(id: string, newPublisher: any) {
        try {
            const response = await axios.patch(
                StudentAttendanceService.url + "/single-edit",
                newPublisher,
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
}

import { Upgrade } from "@mui/icons-material";
import {
    Alert,
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    Grid,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    APIRequestStatus,
    useGetAPI,
    usePostAPI,
} from "../../../services/Hooks";
import { SessionService } from "../../../services/SessionService";
import { StudentService } from "../../../services/StudentService";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";
import { baseAPI } from "../../constants";
import FeedbackSnackbar from "../../TestResult/FeedBackSnackBar";

const studentCols = [
    "Sr No.",
    "Reg No.",
    "Enroll no.",
    "File no.",
    "Name",
    "Father name",
    "Classroom",
];

export const PromoteSection = () => {
    const history = useHistory();
    const [lastSession, setLastSession] = useState<any>();

    const { user, feedback, setFeedback } = useContext(AppContext);
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 0,
        count: 0,
    });

    const [selected, setSelected] = useState<any>({
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        fromSession: "",
        fromClassroom: "",
        fromSection: "",
        toSession: "",
        toClassroom: "",
        toSection: "",
        exclude: [],
        promotionDate: new Date().toISOString().split("T")[0],
    });

    const [
        promoteSection,
        promotionResponse,
        promotionStatus,
        promotionMessage,
    ] = usePostAPI(baseAPI + "/org/academics/promotion/by-section");

    const handlePromoteSection = async () => {
        if (!selected.toSession) {
            setFeedback({
                loading: false,
                message:
                    "You must provide a session  in order to update a student",
                show: true,
                severity: "error",
            });
            return;
        } else if (!selected.toClassroom) {
            setFeedback({
                loading: false,
                message:
                    "You must provide a classroom  in order to update a student",
                show: true,
                severity: "error",
            });
            return;
        } else if (!selected.toSection) {
            setFeedback({
                loading: false,
                message:
                    "You must provide a section  in order to update a student",
                show: true,
                severity: "error",
            });
            return;
        } else {
            promoteSection(selected, {});
        }
    };

    const [students, studentCount, studentStatus, studentMessage] = useGetAPI(
        StudentService.url,
        pagination.page + 1,
        pagination.limit,
        { classroom: selected.fromClassroom, section: selected.fromSection },
        [selected.fromClassroom, selected.fromSection],
        [selected.fromClassroom, selected.fromSection]
    );

    const handleCheckChange = (type: "single" | "all", id?: number) => {
        if (type === "single") {
            if (selected.exclude.includes(id)) {
                setSelected((s: any) => ({
                    ...s,
                    exclude: selected.exclude.filter(
                        (_id: number) => _id !== id
                    ),
                }));
            } else {
                setSelected((s: any) => ({
                    ...s,
                    exclude: [...s.exclude, id],
                }));
            }
        } else {
            if (selected.exclude.length === 0) {
                setSelected((s: any) => ({
                    ...s,
                    exclude: students.map((std: any) => std.id),
                }));
            } else {
                setSelected((s: any) => ({ ...s, exclude: [] }));
            }
        }
    };

    const LastSessionFromCampus = async () => {
        try {
            const [data, err] = await SessionService.getLastSession(1, 1, {
                campus: selected.campus,
            });

            if (err) {
                console.error("Error fetching last session:", err);
                return;
            }

            if (data && data.rows.length) {
                setSelected((prevFilter: any) => ({
                    ...prevFilter,
                    fromSession: data.rows[0].id,
                }));
                setLastSession(data.rows[0]);
            } else {
                setSelected((prevFilter: any) => ({
                    ...prevFilter,
                    session: "",
                }));
                setLastSession(null);
            }
        } catch (error) {
            console.error("Unexpected error in LastSession:", error);
        }
    };

    useEffect(() => {
        if (selected.campus) {
            LastSessionFromCampus();
        }
    }, [selected.campus]);

    useEffect(() => {
        setPagination((_p) => ({ ..._p, count: studentCount }));
    }, [studentCount]);

    useEffect(() => {
        if (["idle", "loading"].includes(promotionStatus)) return;

        setFeedback({
            ...feedback,
            respneseDetail: promotionResponse,
            show: true,
            severity: promotionStatus == "success" ? "success" : "error",
            message: promotionMessage,
        });

        if (promotionStatus == "success" && promotionMessage) {
            history.replace("/student");
        }
    }, [promotionStatus, history, promotionMessage, promotionResponse]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Grid item xs={12}>
                    {feedback.show && (
                        <Alert severity={feedback.severity as any}>
                            {feedback.message}
                        </Alert>
                    )}
                </Grid>
            </Grid>

            {user?.baseUser?.userRegions?.length > 1 && (
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        api="/org/campus"
                        setOutput={(c) =>
                            setSelected({
                                ...selected,
                                campus: c?.id || "",
                            })
                        }
                        label="Campus"
                        labelKey="name"
                        textFieldProps={{ variant: "outlined", size: "small" }}
                    />
                </Grid>
            )}

            <Grid item xs={12} md={4}>
                <Autocomplete
                    api="/org/session"
                    setOutput={(c) =>
                        setSelected({
                            ...selected,
                            fromSession: c?.id || "",
                        })
                    }
                    label="From Session"
                    labelKey="name"
                    textFieldProps={{ variant: "outlined", size: "small" }}
                    apiParams={{ campus: selected.campus, status: "active" }}
                    defaultValue={lastSession}
                    key={lastSession?.id}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete
                    api="/org/academics/classroom"
                    setOutput={(c) =>
                        setSelected({
                            ...selected,
                            fromClassroom: c?.id || "",
                        })
                    }
                    label="From Class"
                    labelKey="name"
                    textFieldProps={{ variant: "outlined", size: "small" }}
                    apiParams={{ campus: selected.campus, status: "active" }}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete
                    api="/org/academics/section"
                    setOutput={(c) =>
                        setSelected({
                            ...selected,
                            fromSection: c?.id || "",
                        })
                    }
                    label="From Section"
                    labelKey="name"
                    textFieldProps={{ variant: "outlined", size: "small" }}
                    apiParams={{
                        classroom: selected.fromClassroom,
                        status: "active",
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <Divider />
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete
                    api="/org/session"
                    setOutput={(c) =>
                        setSelected({
                            ...selected,
                            toSession: c?.id || "",
                        })
                    }
                    label="To Session"
                    labelKey="name"
                    textFieldProps={{ variant: "outlined", size: "small" }}
                    apiParams={{
                        campus: selected.campus,
                        status: "active",
                    }}
                    filter={(opts) =>
                        opts.filter((opt) => opt.id !== selected.fromSession)
                    }
                    dependencies={{ ["fromSession"]: selected.fromSession }}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete
                    api="/org/academics/classroom"
                    setOutput={(c) =>
                        setSelected({
                            ...selected,
                            toClassroom: c?.id || "",
                        })
                    }
                    label="To Class"
                    labelKey="name"
                    textFieldProps={{ variant: "outlined", size: "small" }}
                    apiParams={{ campus: selected.campus, status: "active" }}
                    filter={(opts) =>
                        opts.filter((opt) => opt.id !== selected.fromClassroom)
                    }
                    dependencies={{ ["fromClassroom"]: selected.fromClassroom }}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete
                    api="/org/academics/section"
                    setOutput={(c) =>
                        setSelected({
                            ...selected,
                            toSection: c?.id || "",
                        })
                    }
                    label="To Section"
                    labelKey="name"
                    textFieldProps={{ variant: "outlined", size: "small" }}
                    apiParams={{
                        classroom: selected.toClassroom,
                        status: "active",
                    }}
                    filter={(opts) =>
                        opts.filter((opt) => opt.id !== selected.fromSection)
                    }
                    dependencies={{ ["fromSection"]: selected.fromSection }}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    size="small"
                    label="Promotion Date"
                    fullWidth
                    value={selected.promotionDate}
                    name="date"
                    onChange={(ev) => {
                        setSelected({
                            ...selected,
                            promotionDate: ev.target.value,
                        });
                    }}
                    required
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    defaultValue={new Date().toISOString().split("T")[0]}
                />
            </Grid>

            {selected.campus == "" ||
            selected.fromSession == "" ||
            selected.fromClassroom == "" ||
            selected.fromSection == "" ? (
                <Alert
                    severity="info"
                    style={{ width: "100%", marginTop: "1rem" }}
                >
                    Please Select a campus,session,classroom and section
                </Alert>
            ) : (
                <>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow
                                        sx={{
                                            "&>*": {
                                                bgcolor: "action.hover",
                                            },
                                        }}
                                    >
                                        {studentStatus ===
                                            APIRequestStatus.loading && (
                                            <TableCell colSpan={6}>
                                                <LinearProgress />
                                            </TableCell>
                                        )}
                                        {studentStatus ===
                                            APIRequestStatus.error && (
                                            <TableCell colSpan={6}>
                                                <Alert severity="error">
                                                    {studentMessage}
                                                </Alert>
                                            </TableCell>
                                        )}
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    selected.exclude.length ===
                                                    0
                                                }
                                                onChange={() =>
                                                    handleCheckChange("all")
                                                }
                                                indeterminate={
                                                    selected.exclude.length >
                                                        0 &&
                                                    selected.exclude.length <
                                                        studentCount
                                                }
                                            />
                                        </TableCell>
                                        {studentCols.map((col) => (
                                            <TableCell key={col}>
                                                {col}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {studentStatus ===
                                        APIRequestStatus.success &&
                                        studentCount === 0 && (
                                            <TableRow>
                                                <TableCell colSpan={6}>
                                                    <Alert severity="info">
                                                        No students in classroom
                                                    </Alert>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    {studentStatus ===
                                        APIRequestStatus.success &&
                                        students.map(
                                            (student: any, idx: number) => (
                                                <TableRow key={student.id}>
                                                    <TableCell>
                                                        <Checkbox
                                                            checked={
                                                                !selected.exclude.includes(
                                                                    student.id
                                                                )
                                                            }
                                                            onChange={() =>
                                                                handleCheckChange(
                                                                    "single",
                                                                    student.id
                                                                )
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        {idx + 1}
                                                    </TableCell>

                                                    <TableCell>
                                                        {
                                                            student.admissionNumber
                                                        }
                                                    </TableCell>

                                                    <TableCell>
                                                        {student.enrollmentNo}
                                                    </TableCell>
                                                    <TableCell>
                                                        {student.fileNo}
                                                    </TableCell>

                                                    <TableCell>
                                                        {student.baseUser.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {student.fatherName}
                                                    </TableCell>
                                                    <TableCell>{`${student.classroom.name} (${student.section.name}), ${student.session.name}`}</TableCell>
                                                </TableRow>
                                            )
                                        )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="outlined"
                            fullWidth
                            onClick={handlePromoteSection}
                            sx={{ height: "100%" }}
                            disabled={
                                promotionStatus === APIRequestStatus.loading
                            }
                            endIcon={
                                promotionStatus === APIRequestStatus.loading ? (
                                    <CircularProgress size="1em" />
                                ) : (
                                    <Upgrade />
                                )
                            }
                        >
                            {promotionStatus === APIRequestStatus.loading
                                ? "Promoting..."
                                : "Promote"}
                        </Button>
                    </Grid>
                </>
            )}

            <FeedbackSnackbar feedback={feedback} setFeedback={setFeedback} />
        </Grid>
    );
};

import {
    Avatar,
    Button,
    CircularProgress,
    Grid,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { FormEvent, useContext, useEffect, useState } from "react";
import { CampusService } from "../../../services/CampusService";
import { ClassroomService } from "../../../services/ClassroomService";
import { AddViewContext } from "../../AddView";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";

type Props = {
    row?: any;
    updateMode?: boolean;
};

export const AddClassroom = ({ row, updateMode = false }: Props) => {
    const { open, setOpen, triggerRowsRefetch } = useContext(AddViewContext);
    const { user, feedback, setFeedback } = useContext(AppContext);

    const [classes, setClasses] = useState<any>({
        name: "",
        campus: "",
        educationalCategory: "",
        status: "",
    });

    const [classesCopy, setClassesCopy] = useState<any>({
        name: "",
        campus: "",
        educationalCategory: "",
        status: "",
    });
    // const { feedback, setFeedback } = useContext(AppContext);

    const [getcampuses, setCampuses] = useState([]);
    const [eductaionalCategory, setEductaionalCategory] = useState([]);
    const [classLevel, setClassLevel] = useState([]);

    const getCampus = async () => {
        const [data, err] = await CampusService.getCampus();
        setCampuses(data.rows);
    };

    const getEducationalCategory = async () => {
        const [data, err] = await ClassroomService.getEducationCategories();
        setEductaionalCategory(data.rows);
    };

    const getNextLevel = async () => {
        const [data, err] = await ClassroomService.getNextLevel(classes.campus);
        setClassLevel(data.nextLevel);
    };

    const handleChange = (ev: any) => {
        const value = ev.target.value;
        setClasses({
            ...classes,
            [ev.target.name]: value,
        });
    };

    const handleSubmit = async (ev: FormEvent) => {
        ev.preventDefault();
        setFeedback({ ...feedback, loading: true });

        if (!updateMode) {
            const [data, err] = await ClassroomService.createClassroom({
                ...classes,
                name: classes.name.trim(),
                campus:
                    user.baseUser.userRegions.length > 1
                        ? classes.campus
                        : user.baseUser.userRegions[0].campus.id,
            });
            if (data) {
                setFeedback({
                    loading: false,
                    message: data.message,
                    severity: "success",
                    show: true,
                });
                const [nextLevelData, _err] =
                    await ClassroomService.getNextLevel(
                        user.baseUser.userRegions.length > 1
                            ? classes.campus
                            : user.baseUser.userRegions[0].campus.id
                    );
                if (nextLevelData) {
                    setClassLevel(nextLevelData.level);
                }

                setOpen(false);
                triggerRowsRefetch();
            } else {
                setFeedback({
                    loading: false,
                    message: err,
                    severity: "error",
                    show: true,
                });
            }
        } else {
            const hasChanges =
                JSON.stringify(classes) !== JSON.stringify(classesCopy);

            if (!hasChanges) {
                setFeedback({
                    loading: false,
                    message: "Cannot update class when there is no change.",
                    severity: "error",
                    show: true,
                });
                return;
            }
            const [data, err] = await ClassroomService.editClassroom(row.id, {
                ...classes,
                name: classes.name.trim(),
                campus:
                    user.baseUser.userRegions.length > 1
                        ? classes.campus
                        : user.baseUser.userRegions[0].campus.id,
            });
            if (data) {
                setFeedback({
                    loading: false,
                    message: data.message,
                    severity: "success",
                    show: true,
                });
                const [nextLevelData, _err] =
                    await ClassroomService.getNextLevel(
                        user.baseUser.userRegions.length > 1
                            ? classes.campus
                            : user.baseUser.userRegions[0].campus.id
                    );
                if (nextLevelData) {
                    setClassLevel(nextLevelData.level);
                }

                setOpen(false);
                triggerRowsRefetch();
            } else {
                setFeedback({
                    loading: false,
                    message: err,
                    severity: "error",
                    show: true,
                });
            }
        }
    };

    useEffect(() => {
        getCampus();
        getEducationalCategory();
    }, []);

    useEffect(() => {
        if (classes.campus) {
            getNextLevel();
        }
    }, [classes.campus]);

    useEffect(() => {
        if (updateMode) {
            setClasses({
                name: row?.name,
                campus: row?.campus?.id,
                educationalCategory: row?.educationalCategory,
            });

            setClassesCopy({
                name: row?.name,
                campus: row?.campus?.id,
                educationalCategory: row?.educationalCategory,
            });
        }
    }, [updateMode]);

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={1.5} sx={{ alignItems: "center" }}>
                {/* <Grid item xs={12}>
                    {feedback.show && (
                        <Alert severity={feedback.severity}>
                            {feedback.message}
                        </Alert>
                    )}
                </Grid> */}

                <Grid item container xs={12} justifyContent="space-between">
                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            label="Name"
                            variant="outlined"
                            fullWidth
                            required
                            value={classes.name}
                            name="name"
                            onChange={handleChange}
                            InputProps={{
                                endAdornment: (
                                    <Tooltip title="Classroom sequence number">
                                        <Avatar
                                            sx={{
                                                cursor: "pointer",
                                                width: "24px",
                                                height: "24px",
                                            }}
                                        >
                                            <Typography color="primary">
                                                {updateMode
                                                    ? row?.level
                                                    : classLevel}
                                            </Typography>
                                        </Avatar>
                                    </Tooltip>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        setOutput={(c) =>
                            setClasses({
                                ...classes,
                                status: c?.id || "",
                            })
                        }
                        label="Status"
                        labelKey="name"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                        defaultOptions={[
                            { id: "active", name: "Active" },
                            { id: "inactive", name: "Inactive" },
                        ]}
                        defaultValue={
                            updateMode
                                ? {
                                      id: row?.status,
                                      name: row?.status,
                                  }
                                : undefined
                        }
                    />
                </Grid>

                {user.baseUser.userRegions.length > 1 && (
                    <Grid item xs={12}>
                        <Autocomplete
                            api="/org/campus"
                            setOutput={(c) =>
                                setClasses({
                                    ...classes,
                                    campus: c?.id || "",
                                })
                            }
                            label="Campus"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            defaultValue={updateMode && row.campus}
                        />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <TextField
                        size="small"
                        variant="outlined"
                        select
                        fullWidth
                        label="Educational Category"
                        value={classes.educationalCategory}
                        onChange={handleChange}
                        name="educationalCategory"
                        required
                    >
                        {eductaionalCategory.map((education: any, idx) => (
                            <MenuItem key={idx} value={education.category}>
                                {education.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item mt={1}>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={feedback.loading}
                        endIcon={
                            feedback.loading ? (
                                <CircularProgress size="1em" />
                            ) : null
                        }
                    >
                        {!updateMode
                            ? feedback.loading
                                ? "Adding classroom..."
                                : "Add classroom"
                            : feedback.loading
                            ? "updating classroom..."
                            : "update classroom"}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

import {
    ArrowDownward,
    ArrowUpward,
    Circle,
    ClearAll,
    ToggleOff,
} from "@mui/icons-material";
import {
    Button,
    Chip,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import BaseAccordian from "../../../BaseAccordian";
import { MultiPermissionAuthorize } from "../../../MultiPermissionAuthorize";
import { ClassroomService } from "../../../services/ClassroomService";
import AddView from "../../AddView";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";
import { Search } from "../../Filters/Search";
import { RUDTable } from "../../Table/RUDTable";
import FeedbackSnackbar from "../../TestResult/FeedBackSnackBar";
import { AddClassroom } from "./AddClassroom";

const ChangeClassroomSequence = ({ row, setChangesCount }: any) => {
    const handleLevelChange = async (direction: string) => {
        const [data, err] = await ClassroomService.changeLevel(
            row.id,
            direction
        );

        if (data) {
            setChangesCount((c: number) => c + 1);
        }
    };

    return (
        <div>
            <IconButton onClick={() => handleLevelChange("up")} size="small">
                <Tooltip title="Move up">
                    <ArrowUpward fontSize="small" />
                </Tooltip>
            </IconButton>

            <IconButton onClick={() => handleLevelChange("down")} size="small">
                <Tooltip title="Move down">
                    <ArrowDownward fontSize="small" />
                </Tooltip>
            </IconButton>
        </div>
    );
};

export function Classroom() {
    const [showWithDelay, setShowWithDelay] = useState(false);
    const { user, feedback, setFeedback } = useContext(AppContext);
    const [changes, setChanges] = useState(0);
    const [cats, setCats] = useState<any>({});
    const [filter, setFilter] = useState<any>({
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        educationalCategory: "",
        status: "",
        search: "",
        apply: 0,
    });

    const [clearFilters, setClearFilters] = useState(false);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            campus:
                user?.baseUser?.userRegions?.length > 1
                    ? ""
                    : user?.baseUser?.userRegions[0]?.campus?.id,
            educationalCategory: "",
            status: "",
            apply: 0,
            search: "",
        });
    };
    const [changesCount, setChangesCount] = useState(0);

    const getEducationalCategory = async () => {
        const [data, err] = await ClassroomService.getEducationCategories();

        if (data) {
            const newCats: any = {};

            for (const r of data.rows) {
                newCats[r?.category] = r?.label;
            }
            setCats(newCats);
        }
    };

    useEffect(() => {
        getEducationalCategory();
    }, []);

    useEffect(() => {
        let timer: any;
        if (feedback.show) {
            // Start the delay timer
            timer = setTimeout(() => {
                setShowWithDelay(true);
            }, 500); // Adjust the delay time in milliseconds
        } else {
            // Reset visibility when feedback.show is false
            setShowWithDelay(false);
        }

        return () => clearTimeout(timer); // Clean up the timer
    }, [feedback.show]);

    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="h4" color="primary" gutterBottom>
                    Classes
                </Typography>

                <MultiPermissionAuthorize ops={["CREATE CLASSROOM"]}>
                    <AddView
                        label="add classes"
                        triggerRowsRefetch={() => setChanges(changes + 1)}
                    >
                        <AddClassroom />
                    </AddView>
                </MultiPermissionAuthorize>
            </div>

            <BaseAccordian>
                <Grid container spacing={1}>
                    {user?.baseUser?.userRegions?.length > 1 && (
                        <Grid item xs={12} md={6} lg={3}>
                            <Autocomplete
                                api="/org/campus"
                                setOutput={(c: any) =>
                                    setFilter({
                                        ...filter,
                                        campus: c?.id || "",
                                    })
                                }
                                label="Campus"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                }}
                                clear={clearFilters}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} md={6} lg={3}>
                        {/* <SearchableInput
                            api={`${baseAPI}/org/academics/classroom/educational-categories`}
                            label="EducationalCategory"
                            _name="educationalCategory"
                            filter={filter}
                            setFilter={setFilter}
                            optionsPreprocessor={(opt) => ({
                                id: opt.category,
                                name: opt.label,
                            })}
                        /> */}

                        <Autocomplete
                            api="/org/academics/classroom/educational-categories"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    educationalCategory: c?.id || "",
                                })
                            }
                            label="EducationalCategory"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            processor={(opt) => ({
                                id: opt.category,
                                name: opt.label,
                            })}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Autocomplete
                            setOutput={(c) =>
                                setFilter({
                                    ...filter,
                                    status: c?.id || "",
                                })
                            }
                            label="Status"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            defaultOptions={[
                                { id: "active", name: "Active" },
                                { id: "inactive", name: "Inactive" },
                            ]}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Search
                            filter={filter}
                            setFilter={setFilter}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Button
                            color="primary"
                            fullWidth
                            variant="contained"
                            onClick={() =>
                                setFilter({
                                    ...filter,
                                    apply: filter.apply + 1,
                                })
                            }
                            style={{ height: "100%" }}
                            size="small"
                        >
                            {" "}
                            Search
                        </Button>
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Tooltip title="Clear Filters">
                            <IconButton
                                onClick={handleFilterClear}
                                color="primary"
                            >
                                <ClearAll />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </BaseAccordian>

            <RUDTable
                getter={(page, limit) =>
                    ClassroomService.getClassroom(page, limit, {
                        campus: filter.campus ? filter.campus : undefined,
                        status: filter.status ? filter.status : undefined,
                        educationalCategory: filter.educationalCategory
                            ? filter.educationalCategory
                            : undefined,
                        search: filter.search ? filter.search : undefined,
                    })
                }
                editor={ClassroomService.editClassroom}
                deletor={
                    user?.baseUser?.ops.includes("DELETE CLASSROOM")
                        ? (id: string | number) =>
                              ClassroomService.deleteClassroomSoft(id)
                        : undefined
                }
                rowsPreprocessor={(row) => ({
                    ...row,
                    //in here we will map nested values
                    deletionId: row.id,
                    cat: cats[row?.educationalCategory],
                    campusName: row.campus.name,
                    _status: (
                        <Chip
                            variant="outlined"
                            icon={
                                row.status === "active" ? (
                                    <Circle color="success" fontSize="small" />
                                ) : (
                                    <ToggleOff fontSize="small" color="error" />
                                )
                            }
                            label={row.status}
                            sx={{ textTransform: "capitalize" }}
                            color={
                                row.status === "active" ? "success" : "error"
                            }
                            size="small"
                        />
                    ),
                })}
                readables={{
                    id: "ID",
                    name: "Name",
                    cat: "Educational Category",
                    campusName: "Campus Name",
                    _status: "Status",
                    Edit: "Edit",
                    Changesequence: "Change sequence",
                }}
                customCols={[
                    {
                        header: "Changesequence",
                        content: (row) => (
                            <ChangeClassroomSequence
                                row={row}
                                setChangesCount={setChangesCount}
                            />
                        ),
                    },

                    {
                        header: user?.baseUser?.ops.includes("UPDATE CLASSROOM")
                            ? "Edit"
                            : "",
                        content: (row) => (
                            <MultiPermissionAuthorize
                                ops={["UPDATE CLASSROOM"]}
                            >
                                <AddView
                                    label="Edit classroom"
                                    updateMode
                                    triggerRowsRefetch={() =>
                                        setChanges(changes + 1)
                                    }
                                >
                                    <AddClassroom row={row} updateMode />
                                </AddView>
                            </MultiPermissionAuthorize>
                        ),
                    },
                ]}
                updatingAgents={[
                    cats,
                    changesCount,
                    changes,
                    filter.apply,
                    // filter.search,
                ]}
                ops={{
                    read: "READ CLASSROOM",
                    edit: "",
                    delete: "DELETE CLASSROOM",
                }}
                storageKey="Classes"
            />

            {showWithDelay && (
                <FeedbackSnackbar
                    feedback={feedback}
                    setFeedback={setFeedback}
                />
            )}
        </>
    );
}

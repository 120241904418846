import { makeStyles } from "@material-ui/core";
import {
    Button,
    CircularProgress,
    Grid,
    TextField,
    Theme,
} from "@mui/material";
import { FormEvent, useContext, useEffect, useState } from "react";
import { SubjectService } from "../../../services/SubjectService";
import { AddViewContext } from "../../AddView";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";

const useStyles = makeStyles((theme: Theme) => ({
    breadcrum: {
        padding: theme.spacing(2),
        margin: theme.spacing(2, 0),
    },
}));

type Props = {
    row?: any;
    updateMode?: boolean;
};

const EditSubject = ({ row, updateMode = false }: Props) => {
    const { feedback, setFeedback, user } = useContext(AppContext);
    const { open, setOpen, triggerRowsRefetch } = useContext(AddViewContext);

    const [subject, setSubject] = useState({
        name: "",
        classroom: "",
        publisher: "",
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,

        status: "",
    });

    const [subjectCopy, setSubjectCopy] = useState({
        name: "",
        classroom: "",
        publisher: "",
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,

        status: "",
    });

    const handleChange = (ev: any) => {
        const value = ev.target.value;
        setSubject({
            ...subject,
            [ev.target.name]: value,
        });
    };

    const handleSubmit = async (ev: FormEvent) => {
        ev.preventDefault();
        setFeedback({ ...feedback, loading: true });

        const hasChanges =
            JSON.stringify(subject) !== JSON.stringify(subjectCopy);

        if (!hasChanges) {
            setFeedback({
                loading: false,
                message: "Cannot update subject when there is no change.",
                severity: "error",
                show: true,
            });
            return;
        }

        const [data, err] = await SubjectService.editSubject(row.id, {
            ...subject,
            publisher:
                subject.publisher === "No publisher" ? "" : subject.publisher,
            campus: undefined,
        });
        if (data) {
            setFeedback({
                loading: false,
                message: data.message,
                severity: "success",
                show: true,
            });

            setOpen(false);
        } else {
            setFeedback({
                loading: false,
                message: err,
                severity: "error",
                show: true,
            });
        }
    };

    useEffect(() => {
        if (updateMode) {
            setSubject({
                name: row.name,
                classroom: row?.classroom?.id,
                publisher: row?.publisher?.id,
                campus: row.classroom.campus.id,
                status: row.status,
            });

            setSubjectCopy({
                name: row.name,
                classroom: row?.classroom?.id,
                publisher: row?.publisher?.id,
                campus: row.classroom.campus.id,
                status: row.status,
            });
        }
    }, [updateMode]);
    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={1.5}>
                <Grid item xs={12} md={12}>
                    <TextField
                        size="small"
                        variant="outlined"
                        label="Subject"
                        name="name"
                        required
                        value={subject.name}
                        fullWidth
                        onChange={handleChange}
                    />
                </Grid>

                {user.baseUser.userRegions.length > 1 && (
                    <Grid item xs={12}>
                        <Autocomplete
                            api="/org/campus"
                            setOutput={(c) =>
                                setSubject({
                                    ...subject,
                                    campus: c?.id || "",
                                })
                            }
                            label="Campus"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            defaultValue={updateMode && row.classroom.campus}
                        />
                    </Grid>
                )}

                <Grid item xs={12} md={12}>
                    <Autocomplete
                        api="/org/academics/classroom"
                        setOutput={(c) =>
                            setSubject({
                                ...subject,
                                classroom: c?.id || "",
                            })
                        }
                        label="Classroom"
                        labelKey="name"
                        textFieldProps={{ variant: "outlined", size: "small" }}
                        apiParams={{
                            campus: subject.campus
                                ? subject.campus
                                : row.classroom.campus.id,
                            status: "active",
                        }}
                        defaultValue={updateMode && row.classroom}
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <Autocomplete
                        api="/org/academics/publisher"
                        setOutput={(p) =>
                            setSubject({
                                ...subject,
                                publisher: p?.id || "",
                            })
                        }
                        label="Publisher"
                        labelKey="name"
                        textFieldProps={{ variant: "outlined", size: "small" }}
                        defaultValue={updateMode && row.publisher}
                        apiParams={{ status: "active" }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        setOutput={(c) =>
                            setSubject({
                                ...subject,
                                status: c?.id || "",
                            })
                        }
                        label="Status"
                        labelKey="name"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                        defaultOptions={[
                            { id: "active", name: "Active" },
                            { id: "inactive", name: "Inactive" },
                        ]}
                        defaultValue={
                            updateMode
                                ? {
                                      id: row?.status,
                                      name: row?.status,
                                  }
                                : undefined
                        }
                    />
                </Grid>

                <Grid item xs={12} mt={1}>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={feedback.loading}
                        endIcon={
                            feedback.loading ? (
                                <CircularProgress size="1em" />
                            ) : null
                        }
                    >
                        {!updateMode
                            ? feedback.loading
                                ? "Adding subject..."
                                : "Add subject"
                            : feedback.loading
                            ? "updating subject..."
                            : "update subject"}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default EditSubject;

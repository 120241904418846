import { Check, Close, Visibility, VisibilityOff } from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    Collapse,
    Snackbar,
    Typography,
} from "@mui/material";
import { useState } from "react";

const FeedbackSnackbar = ({ feedback, setFeedback }: any) => {
    const [showResponseDetails, setShowResponseDetails] = useState(false);

    const toggleDetails = () => {
        setShowResponseDetails(!showResponseDetails);
    };

    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }
        setFeedback((f: any) => ({
            ...f,
            show: false,
        }));

        setTimeout(() => {
            setFeedback((f: any) => ({ ...f, message: "" }));
        }, 4000);
    };

    return (
        <Snackbar
            open={feedback.show}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            message="Message"
        >
            <Alert
                severity={feedback?.severity || "info"}
                sx={(theme) => ({
                    overflowY: "auto",
                    maxHeight: theme.spacing(30),
                })}
                action={
                    feedback?.respneseDetail ? (
                        <>
                            <Button
                                color="inherit"
                                size="small"
                                onClick={toggleDetails}
                            >
                                {showResponseDetails ? (
                                    <VisibilityOff />
                                ) : (
                                    <>
                                        <Visibility />
                                    </>
                                )}
                            </Button>

                            <Button
                                onClick={handleClose}
                                color="inherit"
                                size="small"
                            >
                                <Close />
                            </Button>
                        </>
                    ) : (
                        <Button
                            onClick={handleClose}
                            color="inherit"
                            size="small"
                        >
                            <Close />
                        </Button>
                    )
                }
            >
                {feedback.message}

                {feedback?.respneseDetail && (
                    <Collapse
                        in={showResponseDetails}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Box mt={2}>
                            {feedback.respneseDetail.successes?.map(
                                (
                                    suc: {
                                        message: string;
                                        id: number | string;
                                    },
                                    index: number
                                ) => (
                                    <Box key={index}>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "1rem",
                                            }}
                                        >
                                            <Check />
                                            <Typography
                                                variant="subtitle2"
                                                color={"inherit"}
                                            >
                                                {`ID (${suc.id}) - ${suc.message}`}
                                            </Typography>
                                        </div>
                                    </Box>
                                )
                            )}

                            {feedback.respneseDetail.failures?.map(
                                (
                                    err: {
                                        message: string;
                                        id: number | string;
                                    },
                                    index: number
                                ) => (
                                    <Box key={index}>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "1rem",
                                            }}
                                        >
                                            <Close />{" "}
                                            <Typography
                                                variant="subtitle2"
                                                color={"inherit"}
                                            >
                                                {`ID (${err.id}) - ${err.message}`}
                                            </Typography>
                                        </div>
                                    </Box>
                                )
                            )}
                        </Box>
                    </Collapse>
                )}
            </Alert>
        </Snackbar>
    );
};

export default FeedbackSnackbar;

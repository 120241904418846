import { Button, CircularProgress, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useContext, useEffect, useRef, useState } from "react";
import { GET, PATCH } from "../../../services/BaseService";
import { CampusService } from "../../../services/CampusService";
import { S3Service } from "../../../services/S3Service";
import { AddViewContext } from "../../AddView";
import { AppContext } from "../../AppContext";
import { awsDirStruct } from "../../constants";
import AddCustomField from "./AddCustomField";
import ShowCustomField from "./ShowCustomField";

type Props = {
    row?: any;
    updateMode?: boolean;
};

type CustomField = {
    createdAt?: string;
    updatedAt?: string;
    deleted?: string;
    id: number;
    targetType: string;
    targetId: number;
    key: string;
    value: string;
    type: "text" | "image";
    picture: File | Blob;
};

type Campus = {
    name: string;
    address: string;
    FontFamily?: string;
    startTime: string;
    endTime: string;
    latitude: string;
    longitude: string;
    customFields: CustomField[];
};

type CustomFieldFeedback = {
    show: boolean;
    loading: boolean;
    message: string;
    severity: "success" | "error" | "warning";
    deleted?: string;
};

const getCustomFields = async () => {
    const [data, err] = await GET("/customfield/template-component", {
        page: 1,
        limit: 0,
    });

    if (data) {
        return {
            rows: data.rows.filter(
                (field: any) => field.template.name === "campus"
            ),
            error: null,
        };
    } else return { rows: null, error: err };
};

const campusDir = awsDirStruct.campus.picture.split("/");

const AddCampus = ({ row, updateMode = false }: Props) => {
    const formRef = useRef<HTMLFormElement | null>(null);
    const [addCustomFieldBoxOpen, setAddCustomFieldBoxOpen] = useState(false);
    const { open, setOpen, triggerRowsRefetch } = useContext(AddViewContext);

    const { feedback, setFeedback } = useContext(AppContext);
    const [campus, setCampus] = useState<Campus>({
        name: "",
        address: "",
        startTime: "",
        endTime: "",
        latitude: "",
        longitude: "",
        customFields: [],
    });

    const [campusCopy, setCampusCopy] = useState<Campus>({
        name: "",
        address: "",
        startTime: "",
        endTime: "",
        latitude: "",
        longitude: "",
        customFields: [],
    });
    const [customFields, setCustomFields] = useState({});
    const [customFieldsCopy, setCustomFieldsCopy] = useState({});
    const [customFieldsFeedback, setCustomFieldsFeedback] =
        useState<CustomFieldFeedback>({
            show: false,
            loading: false,
            message: "",
            severity: "warning",
        });
    const [filter, setFilter] = useState<any>({ customFields: "" });

    const handleChange = (ev: any) => {
        const value = ev.target.value;
        setCampus({
            ...campus,
            [ev.target.name]: value,
        });
    };

    // =============================================
    //               FORM SUBMISSION
    // =============================================
    const handlesubmit = async (ev: any) => {
        ev.preventDefault();

        setFeedback({ ...feedback, loading: true });

        if (!updateMode) {
            let s3Error = false;

            const campusToSend = {
                ...campus,
                // customFields: campus.customFields.map((field: any) => ({
                //   key: field.key,
                //   value: field.value,
                //   type: field.type,
                // })),
            };

            const [data, err] = await CampusService.createCampus({
                ...campusToSend,
                name: campus.name.trim(),
            });

            if (data) {
                setFeedback({
                    message: data.message,
                    show: true,
                    severity: "success",
                    loading: false,
                });
                const s3MainDir = `${campusDir[0]}/${data.campusId}/${campusDir[1]}`;

                setCustomFieldsFeedback({
                    ...customFieldsFeedback,
                    loading: true,
                });

                for (const field of campus.customFields.filter(
                    (field) => field.type === "image"
                )) {
                    const key = s3MainDir + "/" + field.key.replaceAll(" ", "");
                    const [s3Res, s3Err] = await S3Service.uploadToS3(
                        key,
                        field.picture
                    );

                    if (s3Res) {
                        setCustomFieldsFeedback({
                            severity: "success",
                            show: true,
                            message: s3Res.message,
                            loading: false,
                        });

                        setOpen(false);
                        triggerRowsRefetch();
                    } else {
                        setCustomFieldsFeedback({
                            severity: "error",
                            show: true,
                            message: s3Err,
                            loading: false,
                        });
                    }
                }

                setOpen(false);
                triggerRowsRefetch();
            } else {
                setFeedback({
                    loading: false,
                    message: err,
                    severity: "error",
                    show: true,
                });
            }
        } else {
            // Check for changes
            const hasChanges =
                JSON.stringify(campus) !== JSON.stringify(campusCopy);
            const hasCustomFieldChanges =
                JSON.stringify(customFields) !==
                JSON.stringify(customFieldsCopy);

            // If no changes, show feedback and exit
            if (!hasChanges && !hasCustomFieldChanges) {
                setFeedback({
                    loading: false,
                    message: "Cannot update campus when there is no change.",
                    severity: "error",
                    show: true,
                });
                return;
            }

            // Update Campus if there are changes
            if (hasChanges) {
                const [data, err] = await CampusService.editCampus(row.id, {
                    ...campus,
                    customFields: undefined,
                    name: campus.name.trim(),
                });

                if (data) {
                    setFeedback({
                        message: "Campus updated successfully.",
                        show: true,
                        severity: "success",
                        loading: false,
                    });
                } else {
                    setFeedback({
                        message: err || "Failed to update campus.",
                        show: true,
                        severity: "error",
                        loading: false,
                    });
                }

                triggerRowsRefetch(); // Refresh rows if needed
            }

            // Update Custom Fields if there are changes
            if (hasCustomFieldChanges) {
                const cfDict = Object.values(customFields).map((cf: any) => ({
                    id: cf.id,
                    key: cf.key,
                    value: cf.value,
                }));

                const updatedCustomFields = cfDict.filter((field) =>
                    Boolean(field.value)
                );

                if (updatedCustomFields.length > 0) {
                    try {
                        const promises = updatedCustomFields.map((field: any) =>
                            PATCH("/customfield", field, {
                                id: field.id,
                            })
                        );

                        await Promise.all(promises);

                        setFeedback({
                            message: "Campus updated successfully.",
                            show: true,
                            severity: "success",
                            loading: false,
                        });
                    } catch (error) {
                        setFeedback({
                            message: "Failed to update campus.",
                            show: true,
                            severity: "error",
                            loading: false,
                        });
                    }
                }

                // Handle S3 Uploads for Image Fields
                const s3MainDir = `${campusDir[0]}/${row.id}/${campusDir[1]}`;
                for (const field of campus.customFields.filter(
                    (field: any) => field.type === "image"
                )) {
                    const key = s3MainDir + "/" + field.key.replaceAll(" ", "");

                    const [s3Res, s3Err] = await S3Service.uploadToS3(
                        key,
                        field.picture
                    );

                    if (s3Res) {
                        setCustomFieldsFeedback({
                            severity: "success",
                            show: true,
                            message: s3Res.message,
                            loading: false,
                        });
                    } else {
                        setCustomFieldsFeedback({
                            severity: "error",
                            show: true,
                            message: s3Err || "Failed to upload image.",
                            loading: false,
                        });
                    }
                }

                // Close modal and refresh if needed
                setOpen(false);
            }
        }
    };

    useEffect(() => {
        if (!updateMode) {
            if (filter.customFields) {
                const fields = filter.customFields.template.reduce(
                    (acc: any, curr: any) => {
                        return { ...acc, [curr.key]: { ...curr, value: "" } };
                    },
                    {}
                );

                setCustomFields(fields);
            }

            if (filter.customFields === "all") {
                setCustomFields({});
            }
        }
    }, [filter.customFields]);

    useEffect(() => {
        setCampus({ ...campus, customFields: Object.values(customFields) });
    }, [customFields]);

    useEffect(() => {
        if (updateMode) {
            setCampus({
                name: row.name,
                address: row.address,
                startTime: row.startTime,
                endTime: row.endTime,
                latitude: row.Editlatitude,
                longitude: row.Editlongitude,
                customFields: row.customFields,
            });

            setCampusCopy({
                name: row.name,
                address: row.address,
                startTime: row.startTime,
                endTime: row.endTime,
                latitude: row.Editlatitude,
                longitude: row.Editlongitude,
                customFields: row.customFields,
            });

            const fields = row.customFields.reduce((acc: any, curr: any) => {
                // { ...acc, [curr.key]: curr };

                if (!acc[curr.key]) {
                    acc[curr.key] = curr;
                }
                return acc;
            }, {});

            setCustomFields(fields);
            setCustomFieldsCopy(fields);
        }
    }, [updateMode]);

    useEffect(() => {
        if (updateMode) return;

        getCustomFields().then(({ rows, error }) =>
            setCustomFields(
                rows.reduce(
                    (prev: any, curr: any) => ({ ...prev, [curr.key]: curr }),
                    {}
                )
            )
        );
    }, []);

    return (
        <>
            <AddCustomField
                open={addCustomFieldBoxOpen}
                setOpen={setAddCustomFieldBoxOpen}
                item={campus}
                setItem={setCampus}
                updateMode={updateMode}
            />

            <Box component="form" onSubmit={handlesubmit} ref={formRef}>
                <Grid container spacing={1.5}>
                    <Grid item xs={12}>
                        {/* {feedback.show && (
                            <Alert severity={feedback.severity}>
                                {feedback.message}
                            </Alert>
                        )} */}
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            variant="outlined"
                            label="Name"
                            value={campus.name}
                            name="name"
                            fullWidth
                            onChange={handleChange}
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            variant="outlined"
                            label="Address"
                            value={campus.address}
                            name="address"
                            fullWidth
                            onChange={handleChange}
                            required
                            multiline
                            minRows={2}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            size="small"
                            variant="outlined"
                            label="Start time"
                            type="time"
                            value={campus.startTime}
                            name="startTime"
                            fullWidth
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            size="small"
                            variant="outlined"
                            label="End time"
                            type="time"
                            value={campus.endTime}
                            name="endTime"
                            fullWidth
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            size="small"
                            variant="outlined"
                            label="Latitude"
                            value={campus.latitude}
                            name="latitude"
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            size="small"
                            variant="outlined"
                            label="Longitude"
                            value={campus.longitude}
                            name="longitude"
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>

                    {campus.customFields.length > 0 && (
                        <>
                            <Grid item xs={12}>
                                <Typography>Custom Fields</Typography>
                            </Grid>

                            {Object.values(customFields).map((field: any) => (
                                <Grid key={field.id} xs={6} item>
                                    <ShowCustomField
                                        field={field}
                                        items={customFields}
                                        setItem={setCustomFields}
                                        feedback={customFieldsFeedback}
                                    />
                                </Grid>
                            ))}
                        </>
                    )}

                    <Grid item xs={12} mt={1}>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={feedback.loading}
                            endIcon={
                                feedback.loading ? (
                                    <CircularProgress size="1em" />
                                ) : null
                            }
                        >
                            {!updateMode
                                ? feedback.loading
                                    ? "Adding campus..."
                                    : "Add campus"
                                : feedback.loading
                                ? "updating campus..."
                                : "update campus"}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default AddCampus;

// ==================================================================================
// Campus Creation Old Login
// ==================================================================================

/* 
        let newCustomFields: any[] = [];
        for (const cs1 of campus.customFields) {
          for (const cs2 of data.customFields) {
            if (cs1.key === cs2.key && cs1.type === "image") {
              newCustomFields.push({ ...cs1, id: cs2.id });
            }
          }
        }

        const s3MainDir = `${campusDir[0]}/${data.campusId}/${campusDir[1]}`;

        for (const field of newCustomFields) {
          const s3Key = s3MainDir + `/${field.key.replaceAll(" ", "")}`;

          const [s3Data, s3Err] = await S3Service.uploadToS3(
            s3Key,
            field.picture
          );

          if (s3Err) {
            await CustomFieldService.deleteHard(field.id);
            await CampusService.deleteCampusHard(data.campusId);
            setFeedback({
              loading: false,
              message:
                "One or more picture uploads failed, campus hasn't been created",
              severity: "warning",
              show: true,
            });
            s3Error = true;
          }
        }

        if (!s3Error) {
          setFeedback({
            loading: false,
            message: data.message,
            severity: "success",
            show: true,
          });
        } */

// ==================================================================================
// Campus Updation Old Logic
// ==================================================================================

/* 
      const campusToSend = {
        ...campus,
        customFields: undefined,
      };

      const [data, err] = await CampusService.editCampus(row.id, campusToSend);

      if (data) {
        if (campus.customFields.length > 0) {
          const originalCustomFieldKeys = row.customFields.map(
            (field: any) => field.key
          );
          const originalCustomFieldValues = row.customFields.map(
            (field: any) => field.value
          );

          const fieldsToBeCreated = campus.customFields.filter(
            (field) => !originalCustomFieldKeys.includes(field.key)
          );

          const fieldsToBeUpdated = campus.customFields
            .filter(
              (field) =>
                !(
                  originalCustomFieldKeys.includes(field.key) &&
                  originalCustomFieldValues.includes(field.value)
                )
            )
            .filter((f) => !fieldsToBeCreated.includes(f));

          if (fieldsToBeUpdated.length > 0) {
            const promises = fieldsToBeUpdated.map((field) =>
              PATCH("/customfield", field, { id: field.id })
            );

            Promise.all(promises)
              .then((result: any) =>
                setFeedback({
                  message: result.message,
                  show: true,
                  severity: "success",
                  loading: false,
                })
              )
              .catch((err) =>
                setFeedback({
                  message: err,
                  show: true,
                  severity: "error",
                  loading: false,
                })
              );
          }

          const newCustomFields = fieldsToBeCreated.map((field: any) => ({
            key: field.key,
            value: field.value,
            picture: field.picture,
            type: field.type,
            targetType: "campus",
            targetId: row.id,
          }));

          const [newFieldsRes, newFieldsErr] = await POST("/customfield", {
            customFields: newCustomFields,
          });

          if (newFieldsRes) {
            setFeedback({
              message: newFieldsRes.message,
              show: true,
              severity: "success",
              loading: false,
            });
          }
          if (newFieldsErr) {
            setFeedback({
              message: newFieldsErr,
              show: true,
              severity: "error",
              loading: false,
            });
          }
        }

        for (const field of customFields.image) {
          const s3Key = `${campusDir[0]}/${row.id}/${
            campusDir[1]
          }/${field.key.replaceAll(" ", "")}`;

          const [s3Data, s3Err] = await S3Service.uploadToS3(
            s3Key,
            field?.picture
          );

          if (s3Err) {
            await CustomFieldService.deleteHard(field.id);
            await CampusService.deleteCampusHard(data.campusId);
            setFeedback({
              loading: false,
              message:
                "One or more picture uploads failed, campus hasn't been created",
              severity: "warning",
              show: true,
            });
          }
        }

        setFeedback({
          loading: false,
          message: data.message,
          severity: "success",
          show: true,
        });
      } else {
        setFeedback({
          loading: false,
          message: err,
          severity: "error",
          show: true,
        });
      } */

/*  {customFields.text.length > 0 && (
            <Grid item container xs={12} spacing={1.5}>
              <Grid item xs={12} mt={1.5} mb={0.5}>
                <Typography>Custom Fields</Typography>
              </Grid>

              {customFields.text.map((field) => (
                <Grid item key={field.key} xs={6}>
                  <TextField
                    fullWidth
                    onChange={(e) => handleCustomFieldChange(e, field)}
                    size="small"
                    label={field.key}
                    value={field.value}
                    InputLabelProps={{
                      shrink: true,
                      sx: { textTransform: "capitalize" },
                    }}
                    InputProps={{
                      endAdornment:
                        customFieldsFeedback.loading &&
                        customFieldsFeedback.deleted === field.key ? (
                          <CircularProgress size="1.25rem" />
                        ) : (
                          <IconButton
                            size="small"
                            onClick={
                              row?.customFields.includes(field)
                                ? () => handleCustomFieldDelete(field)
                                : () =>
                                    setCampus(() => ({
                                      ...campus,
                                      customFields: [
                                        ...customFields.text.filter(
                                          (f) => field.key !== f.key
                                        ),
                                        ...customFields.image,
                                      ],
                                    }))
                            }
                          >
                            <Close fontSize="small" />
                          </IconButton>
                        ),
                    }}
                    helperText={
                      customFieldsFeedback.show &&
                      customFieldsFeedback.deleted === field.key
                        ? customFieldsFeedback.message
                        : ""
                    }
                    FormHelperTextProps={{
                      style: {
                        color: theme.palette.success.main,
                      },
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          )}

          {customFields.image.length > 0 && (
            <Grid item container spacing={1.5} xs={12}>
              <Grid item xs={12}>
                <Typography>Images</Typography>
              </Grid>

              {customFields.image.map((image: any) => (
                <Grid item key={image.key} xs={3}>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    badgeContent={
                      <IconButton
                        sx={{
                          width: "25px",
                          height: "25px",
                        }}
                        onClick={
                          row?.customFields
                            .map((f: CustomField) => f.key + "")
                            .includes(image.key)
                            ? () => deleteCustomFieldImage(image)
                            : () =>
                                setCampus(() => ({
                                  ...campus,
                                  customFields: [
                                    ...customFields.text,
                                    ...customFields.image.filter(
                                      (img) => image.key !== img.key
                                    ),
                                  ],
                                }))
                        }
                      >
                        <Close sx={{ width: "15px" }} />
                      </IconButton>
                    }
                  >
                    <Avatar
                      style={{
                        width: "75px",
                        height: "75px",
                      }}
                      src={
                        updateMode
                          ? image.picture
                            ? URL.createObjectURL(image.picture)
                            : image.src
                          : URL.createObjectURL(image.picture)
                      }
                      variant="rounded"
                    />

                    {customFieldsFeedback.show &&
                      customFieldsFeedback.deleted === image.key && (
                        <span
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "rgba(0,0,0,0.5)",
                            borderRadius: "8px",
                          }}
                        >
                          <CircularProgress size="1.5rem" />
                        </span>
                      )}
                  </Badge>

                  <Typography>
                    {!(
                      customFieldsFeedback.show &&
                      customFieldsFeedback.deleted === image.key
                    )
                      ? image.key
                      : "Deleting..."}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          )} 
          
          
          
          
          
          
          
          
          
          
          
          
          const handleCustomFieldChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: CustomField
  ) => {
    const updatedCustomFields = campus.customFields.map((f: CustomField) =>
      f.key === field.key ? { ...field, value: e.target.value } : f
    );

    setCampus(() => ({ ...campus, customFields: updatedCustomFields }));
  };

  const handleCustomFieldDelete = async (field: CustomField) => {
    setCustomFieldsFeedback(() => ({
      ...customFieldsFeedback,
      loading: true,
      deleted: field.key,
    }));

    const [data, err] = await DELETE_HARD("/customfield", field.id);

    if (data) {
      setCustomFieldsFeedback(() => ({
        message: "Field deleted successfull!",
        show: true,
        loading: false,
        severity: "success",
      }));

      setTimeout(
        () =>
          setCampus({
            ...campus,
            customFields: campus.customFields.filter(
              (f) => field.key !== f.key
            ),
          }),
        2000
      );
    } else {
      setCustomFieldsFeedback({
        message: "Failed to delete custom field!",
        show: true,
        loading: false,
        severity: "error",
        deleted: undefined,
      });
    }
  };

  const deleteCustomFieldImage = async (field: CustomField) => {
    setCustomFieldsFeedback(() => ({
      ...customFieldsFeedback,
      show: true,
      deleted: field.key,
    }));

    const [data, err] = await DELETE_HARD(`/customfield`, field.id);

    if (data) {
      const key = `${campusDir[0]}/${row.id}/${campusDir[1]}/${field.key}`;

      const [s3data, s3err] = await S3Service.deleteS3ByKey(key);

      if (s3data) {
        setCustomFieldsFeedback({
          loading: false,
          show: true,
          message: s3data.message,
          severity: "success",
        });

        setCampus(() => ({
          ...campus,
          customFields: campus.customFields.filter((f) => field.id !== f.id),
        }));
      } else {
        setCustomFieldsFeedback({
          loading: false,
          show: true,
          message: "",
          severity: "error",
        });
      }
    }
  };
          
          




  const getCustomFieldImages = async () => {
    const textCustomFields = row.customFields.filter(
      (field: CustomField) => field.type === "text"
    );

    const imageCustomFields = row.customFields.filter(
      (field: CustomField) => field.type === "image"
    );

    let imgWithSrc: any = [];

    if (imageCustomFields.length > 0) {
      for (const imageField of imageCustomFields) {
        const key = `${campusDir[0]}/${imageField.targetId}/${campusDir[1]}/${imageField.key}`;
        const [data, err] = await S3Service.getS3ByKey(key);

        if (data) {
          imgWithSrc.push({ ...imageField, src: data.url });
        }
      }
    }

    setCampus({
      name: row.name,
      address: row.address,
      startTime: row.startTime,
      endTime: row.endTime,
      latitude: row.latitude,
      longitude: row.longitude,
      customFields: [...textCustomFields, ...imgWithSrc],
    });
  };
          
          */

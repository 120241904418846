import { ClearAll, ExpandMore } from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Grid,
    IconButton,
    Tooltip,
} from "@mui/material";
import { useContext, useState } from "react";
import { AppContext } from "../AppContext";
import { Autocomplete } from "../AutoComplete";
import { Search } from "../Filters/Search";

export const FilterStudent = ({
    filter,
    setFilter,
    exclude = {
        campus: false,
        session: false,
        classroom: false,
        section: false,
        search: false,
    },
}: any) => {
    const [clearFilters, setClearFilters] = useState(false);
    const { user } = useContext(AppContext);
    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            campus: "",
            session: "",
            classroom: "",
            sectionId: "",
            search: "",
            apply: 0,
            clear: true,
        });
    };
    return (
        <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>
                Select students
            </AccordionSummary>

            <AccordionDetails>
                <Grid container spacing={1}>
                    {!exclude.campus &&
                        user?.baseUser?.userRegions?.length > 1 && (
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    api="/org/campus"
                                    setOutput={(campus) =>
                                        setFilter({
                                            ...filter,
                                            campus: campus.id,
                                        })
                                    }
                                    labelKey="name"
                                    textFieldProps={{ size: "small" }}
                                    label="Campus"
                                    defaultValue={filter.campus ?? undefined}
                                    clear={clearFilters}
                                />
                            </Grid>
                        )}

                    {!exclude.session && (
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                api="/org/session"
                                setOutput={(session) =>
                                    setFilter({
                                        ...filter,
                                        session: session.id,
                                    })
                                }
                                labelKey="name"
                                textFieldProps={{ size: "small" }}
                                label="Session"
                                apiParams={{
                                    campus: filter.campus,
                                    status: "active",
                                }}
                                clear={clearFilters}
                            />
                        </Grid>
                    )}

                    {!exclude.classroom && (
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                api="/org/academics/classroom"
                                setOutput={(classroom) =>
                                    setFilter({
                                        ...filter,
                                        classroom: classroom.id,
                                    })
                                }
                                labelKey="name"
                                textFieldProps={{ size: "small" }}
                                label="Class"
                                apiParams={{
                                    campus: filter.campus,
                                    status: "active",
                                }}
                                clear={clearFilters}
                            />
                        </Grid>
                    )}

                    {!exclude.section && (
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                api="/org/academics/section"
                                setOutput={(row) =>
                                    setFilter({
                                        ...filter,
                                        section: row.id,
                                    })
                                }
                                labelKey="name"
                                textFieldProps={{ size: "small" }}
                                label="Section"
                                apiParams={{
                                    classroom: filter.classroom,
                                    status: "active",
                                }}
                                clear={clearFilters}
                            />{" "}
                        </Grid>
                    )}

                    {!exclude.search && (
                        <Grid item xs={12} md={4}>
                            <Search
                                filter={filter}
                                setFilter={setFilter}
                                textFieldProps={{ size: "small" }}
                                btnProps={{ size: "small" }}
                                clear={clearFilters}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} md={4}>
                        <Button
                            color="primary"
                            fullWidth
                            variant="contained"
                            onClick={() =>
                                setFilter({
                                    ...filter,
                                    apply: filter.apply + 1,
                                    clear: false,
                                })
                            }
                            style={{ height: "100%" }}
                            size="small"
                        >
                            {" "}
                            Search
                        </Button>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Tooltip title="Clear Filters">
                            <IconButton
                                onClick={handleFilterClear}
                                color="primary"
                            >
                                <ClearAll />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

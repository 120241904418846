import { ClearAll, Close, Done, Edit } from "@mui/icons-material";
import {
    Button,
    Chip,
    Grid,
    IconButton,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import BaseAccordian from "../../BaseAccordian";
import { MultiPermissionAuthorize } from "../../MultiPermissionAuthorize";
import { FeeService } from "../../services/FeeService";
import { AppContext } from "../AppContext";
import { Autocomplete } from "../AutoComplete";
import { Search } from "../Filters/Search";
import { RUDTable } from "../Table/RUDTable";
import { currencyFormatter, dateFormatter } from "../constants";
import { SingleFeeEdit } from "./SingleFeeEdit";

const initialFilter = {
    campus: "",
    session: "",
    classroom: "",
    complete: false,
    student: "",
    month: "",
    search: "",
    apply: 0,
};

export const monthsMap: { [key: string | number]: string } = {
    1: "January",
    2: "Febraury",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
};

const FeeList = () => {
    const { user } = useContext(AppContext);
    const [filter, setFilter] = useState({
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        session: "",
        classroom: "",
        complete: false,
        student: "",
        month: "",
        search: "",
        apply: 0,
    });
    const [singleEditOpen, setSingleEditOpen] = useState(false);
    const [feeToEdit, setFeeToEdit] = useState<any>();
    const location = useLocation();

    const [clearFilters, setClearFilters] = useState(false);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter(initialFilter);
    };

    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Fees
                    </Typography>
                </Grid>

                <Grid xs={12}>
                    <BaseAccordian>
                        <Grid container spacing={2}>
                            {user?.baseUser?.userRegions?.length > 1 && (
                                <Grid item xs={12} md={6} lg={3}>
                                    <Autocomplete
                                        api="/org/campus"
                                        setOutput={(item) =>
                                            setFilter({
                                                ...filter,
                                                campus: item.id,
                                            })
                                        }
                                        labelKey="name"
                                        textFieldProps={{ size: "small" }}
                                        label="Campus"
                                        clear={clearFilters}
                                    />
                                </Grid>
                            )}

                            <Grid item xs={12} md={6} lg={3}>
                                <Autocomplete
                                    api="/org/session"
                                    setOutput={(item) =>
                                        setFilter({
                                            ...filter,
                                            session: item.id,
                                        })
                                    }
                                    labelKey="name"
                                    textFieldProps={{ size: "small" }}
                                    label="Session"
                                    apiParams={{
                                        campus: filter.campus,
                                        status: "active",
                                    }}
                                    clear={clearFilters}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={3}>
                                <Autocomplete
                                    api="/org/academics/classroom"
                                    setOutput={(item) =>
                                        setFilter({
                                            ...filter,
                                            classroom: item.id,
                                        })
                                    }
                                    labelKey="name"
                                    textFieldProps={{ size: "small" }}
                                    label="Class"
                                    apiParams={{
                                        campus: filter.campus,
                                        status: "active",
                                    }}
                                    clear={clearFilters}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={3}>
                                <TextField
                                    label="Month"
                                    name="month"
                                    onChange={(ev: any) =>
                                        setFilter({
                                            ...filter,
                                            month: ev.target.value,
                                        })
                                    }
                                    select
                                    fullWidth
                                    size="small"
                                >
                                    {Object.entries(monthsMap).map(
                                        ([key, value]) => (
                                            <MenuItem value={key}>
                                                {value}
                                            </MenuItem>
                                        )
                                    )}
                                </TextField>
                            </Grid>

                            <Grid item xs={12} md={6} lg={3}>
                                <Search
                                    filter={filter}
                                    setFilter={setFilter}
                                    clear={clearFilters}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={3}>
                                <Button
                                    color="primary"
                                    fullWidth
                                    variant="contained"
                                    onClick={() =>
                                        setFilter({
                                            ...filter,
                                            apply: filter.apply + 1,
                                        })
                                    }
                                    style={{ height: "100%" }}
                                    size="small"
                                >
                                    {" "}
                                    Search
                                </Button>
                            </Grid>

                            <Grid item xs={12} md={6} lg={3}>
                                <Tooltip title="Clear Filters">
                                    <IconButton
                                        onClick={handleFilterClear}
                                        color="primary"
                                    >
                                        <ClearAll />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </BaseAccordian>
                </Grid>
            </Grid>

            <RUDTable
                getter={(page, limit) =>
                    FeeService.getAppliedFees(page, limit, {
                        campus: filter.campus ? filter.campus : undefined,
                        classroom: filter.classroom
                            ? filter.classroom
                            : undefined,
                        session: filter.session ? filter.session : undefined,
                        complete: filter.complete ? filter.complete : undefined,
                        student: filter.student ? filter.student : undefined,
                        month: filter.month ? filter.month : undefined,
                        search: filter.search ? filter.search : undefined,
                    })
                }
                readables={{
                    id: "ID",
                    studentName: "Student",
                    monthName: "Fee Month",
                    totalAmountInPkr: "Total Fee",
                    discountInPkr: "Discount",
                    payableInPkr: "Payable Amount",
                    fileNo: "File No",
                    enrollmentNo: "Enroll No",
                    dueDateFormatted: "Due Date",
                    completionStatus: "Fee Status",
                    Edit: "Edit",
                }}
                rowsPreprocessor={(row) => ({
                    ...row,
                    totalAmountInPkr: currencyFormatter(row.totalAmount),
                    monthName:
                        monthsMap[row.month + ""] +
                        ", " +
                        row.dueDate.split("-", 1)[0],
                    studentName: row.student?.baseUser.name,
                    discountInPkr: currencyFormatter(row.discount),
                    payableInPkr: currencyFormatter(
                        row.totalAmount - row.discount
                    ),
                    fileNo: row.student.fileNo,
                    enrollmentNo: row.student.enrollmentNo,

                    dueDateFormatted: (
                        <Chip
                            color={
                                new Date(row.dueDate).getTime() >
                                new Date().getTime()
                                    ? undefined
                                    : "primary"
                            }
                            label={dateFormatter.format(new Date(row.dueDate))}
                            variant="outlined"
                            size="small"
                            title="asdf"
                        />
                    ),
                    completionStatus: (
                        <Chip
                            icon={
                                row.complete ? (
                                    <Done fontSize="small" />
                                ) : (
                                    <Close fontSize="small" />
                                )
                            }
                            label={row.complete ? "Payed" : "Not Paid"}
                            color={row.complete ? "success" : undefined}
                            variant="outlined"
                            size="small"
                        />
                    ),
                })}
                ops={{ read: "READ FEE", edit: "", delete: "" }}
                editor={(...args: any) => {
                    throw new Error("Function not implemented.");
                }}
                updatingAgents={[filter.apply]}
                customCols={[
                    {
                        header: user?.baseUser?.ops.includes("UPDATE FEE")
                            ? "Edit"
                            : "",

                        content: (row) => (
                            <MultiPermissionAuthorize ops={["UPDATE FEE"]}>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setSingleEditOpen(true);
                                        setFeeToEdit(row);
                                    }}
                                >
                                    <Edit fontSize="small" />
                                </IconButton>
                            </MultiPermissionAuthorize>
                        ),
                    },
                ]}
                storageKey="fee List"
            />

            <SingleFeeEdit
                open={singleEditOpen}
                setOpen={setSingleEditOpen}
                fee={feeToEdit}
            />
        </div>
    );
};

export default FeeList;

// {
//     "id": 1,
//     "createdAt": "2023-10-23T10:23:53.225Z",
//     "updatedAt": "2023-10-23T10:23:53.225Z",
//     "deleted": null,
//     "discount": 500,
//     "status": "",
//     "month": 1,
//     "dueDate": "2023-10-27T19:00:00.000Z",
//     "totalAmount": 5000,
//     "complete": false,
//     "student": {
//         "id": 2,
//         "createdAt": "2023-10-05T08:55:45.674Z",
//         "updatedAt": "2023-10-16T12:09:10.000Z",
//         "deleted": null,
//         "fatherName": "std father one",
//         "dob": "2023-10-03T00:00:00.000Z",
//         "gender": "male",
//         "fatherCNIC": "15603",
//         "studentCNIC": "124",
//         "fileNo": "1",
//         "enrollmentNo": "1",
//         "slcNo": "1",
//         "fatherMobile": "3459514661",
//         "fatherWhatsapp": "123",
//         "currentAddress": "Shahdara Watkay Mingora Swat",
//         "permanentAddress": "asdf",
//         "prePrimaryNo": 2,
//         "primaryNo": 0,
//         "middleNo": 0,
//         "highNo": 0,
//         "admissionDate": "2023-10-04T00:00:00.000Z",
//         "admissionStatus": "confirmed",
//         "status": "withdrawn",
//         "advanceBalance": 0,
//         "customFields": [
//             {
//                 "id": 9,
//                 "createdAt": "2023-10-05T08:55:45.708Z",
//                 "updatedAt": "2023-10-06T06:21:30.000Z",
//                 "deleted": null,
//                 "targetType": "student",
//                 "targetId": 2,
//                 "key": "Blood Group",
//                 "value": "b-ve",
//                 "type": "text"
//             },
//             {
//                 "id": 10,
//                 "createdAt": "2023-10-05T08:55:45.720Z",
//                 "updatedAt": "2023-10-06T06:21:30.000Z",
//                 "deleted": null,
//                 "targetType": "student",
//                 "targetId": 2,
//                 "key": "Emergency Contact No",
//                 "value": "34563456",
//                 "type": "text"
//             },
//             {
//                 "id": 11,
//                 "createdAt": "2023-10-05T08:55:45.724Z",
//                 "updatedAt": "2023-10-05T08:55:45.724Z",
//                 "deleted": null,
//                 "targetType": "student",
//                 "targetId": 2,
//                 "key": "test imagge",
//                 "value": "image/jpeg",
//                 "type": "image"
//             }
//         ]
//     },
//     "session": {
//         "id": 1,
//         "createdAt": "2023-02-12T15:01:11.402Z",
//         "updatedAt": "2023-10-17T11:05:39.748Z",
//         "deleted": null,
//         "name": "2011 - 2012",
//         "start": "2011-01-01",
//         "end": "2011-12-31"
//     },
//     "classroom": {
//         "id": 1,
//         "createdAt": "2023-02-12T15:05:18.590Z",
//         "updatedAt": "2023-02-12T15:05:18.000Z",
//         "deleted": null,
//         "name": "Play Group - (PG)",
//         "level": 0,
//         "educationalCategory": "prePrimary",
//         "campus": {
//             "id": 1,
//             "createdAt": "2023-02-12T14:55:32.809Z",
//             "updatedAt": "2023-10-13T11:04:21.000Z",
//             "deleted": null,
//             "name": "Warsak Jr. Campus",
//             "address": "Babu Ghari Chowk, Near Meezan Bank, Warsak Road Peshawar",
//             "bucket": "campus_1",
//             "startTime": "07:00:00",
//             "endTime": "16:00:00",
//             "latitude": "123'456''",
//             "longitude": "456'789''",
//             "header": "{\"campusName\":true,\"campusAddress\":true,\"clientEmail\":true,\"clientPhone\":true}",
//             "customFields": []
//         },
//         "section": [
//             {
//                 "id": 1,
//                 "createdAt": "2023-02-12T15:13:03.414Z",
//                 "updatedAt": "2023-02-12T15:13:03.414Z",
//                 "deleted": null,
//                 "name": "Rose"
//             },
//             {
//                 "id": 2,
//                 "createdAt": "2023-02-12T15:13:03.423Z",
//                 "updatedAt": "2023-02-12T15:13:03.423Z",
//                 "deleted": null,
//                 "name": "Jasmin"
//             },
//             {
//                 "id": 3,
//                 "createdAt": "2023-02-12T15:13:03.438Z",
//                 "updatedAt": "2023-02-12T15:13:03.438Z",
//                 "deleted": null,
//                 "name": "Lily"
//             },
//             {
//                 "id": 4,
//                 "createdAt": "2023-02-12T15:13:03.444Z",
//                 "updatedAt": "2023-02-12T15:13:03.444Z",
//                 "deleted": null,
//                 "name": "Daffodils"
//             },
//             {
//                 "id": 5,
//                 "createdAt": "2023-09-30T09:01:40.175Z",
//                 "updatedAt": "2023-09-30T09:11:18.000Z",
//                 "deleted": null,
//                 "name": "roseasdf"
//             },
//             {
//                 "id": 6,
//                 "createdAt": "2023-09-30T09:01:40.180Z",
//                 "updatedAt": "2023-09-30T09:01:40.180Z",
//                 "deleted": null,
//                 "name": "lily"
//             }
//         ]
//     }
// }

import {
    AttachMoneyOutlined,
    DeleteOutlined,
    EditOutlined,
    List as ListIcon,
    OpenInNewOutlined,
    ReceiptOutlined,
} from "@mui/icons-material";
import {
    Avatar,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Icon,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import {
    ChangeEvent,
    FormEvent,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { FamilyService } from "../../services/FamilyService";
import { APIRequestStatus, useGetAPI } from "../../services/Hooks";
import { S3Service } from "../../services/S3Service";
import { StudentService } from "../../services/StudentService";
import { AppContext } from "../AppContext";
import { awsDirStruct } from "../constants";
import PrintTable from "../Export/PrintTable";
import { Search } from "../Filters/Search";
import Footer from "../Settings/Footer/Footer";
import { HeaderStructure } from "../Settings/Header/HeaderStructure";

const ShowFamilyMembers = ({ show, setShow, members }: any) => {
    const { user } = useContext(AppContext);

    const headerVariables = useMemo(() => {
        if (!user?.baseUser?.userRegions.length) return;

        console.log(user.baseUser.userRegions);

        try {
            return JSON.parse(user?.baseUser?.userRegions[0].campus.header);
        } catch (error) {
            return {
                campusName: false,
                campusAddress: false,
                clientEmail: false,
                clientPhone: false,
            };
        }
    }, [user]);
    const [srcs, setSrcs] = useState<any>({});
    const getFamilyMemberImages = async () => {
        const images: any = {};

        for (const member of members) {
            const studentImagesSrcs = [];
            const [studentImagesData, studentImagesErr] =
                await StudentService.getStudentImages(member.member.id);

            if (studentImagesData) {
                for (const image of studentImagesData.images) {
                    const key = `${awsDirStruct.profile.picture}/${image.id}`;
                    const [data, err] = await S3Service.getS3ByKey(key);

                    if (data) {
                        studentImagesSrcs.push(data.url);
                    }
                }

                if (studentImagesSrcs.length > 0) {
                    images[member.id] = studentImagesSrcs.slice(-1)[0];
                }
            }
        }

        setSrcs(images);
    };

    useEffect(() => {
        getFamilyMemberImages();
    }, []);

    return show ? (
        <Dialog open={show} fullWidth>
            <DialogTitle>Family members</DialogTitle>
            <DialogContent>
                <List>
                    {members.map((fm: any) => (
                        <ListItem key={fm.id}>
                            <ListItemAvatar>
                                <Avatar src={srcs[fm.member.id] || ""} />
                            </ListItemAvatar>
                            <ListItemText primary={fm.member.baseUser.name} />
                            <ListItemSecondaryAction>
                                <IconButton
                                    onClick={() => {
                                        window
                                            .open(
                                                `/show-student/${fm.member.baseUser.username}`,
                                                "_blank"
                                            )
                                            ?.focus();
                                    }}
                                >
                                    <OpenInNewOutlined />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setShow(false);
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    ) : null;
};

export const ViewFamily = () => {
    const [printMode, setPrintMode] = useState(false);
    const studentFormRef = useRef<any>(null);
    const { user } = useContext(AppContext);
    const [filter, setFilter] = useState({ search: "", apply: 0 });
    const [rowsCount, setRowsCount] = useState(0);
    const [pagination, setPagination] = useState<any>({ page: 0, limit: 100 });
    const [searchTrigger, setSearchTrigger] = useState(0);

    const [activeFamily, setActiveFamily] = useState<number | null>(null);
    const [showMembers, setShowMembers] = useState(false);

    const [deleteStatus, setDeleteStatus] = useState<any>({});

    const handleSearch = (ev: FormEvent<any>) => {
        ev.preventDefault();

        setSearchTrigger((n) => n + 1);
    };

    const handleChange = (ev: ChangeEvent<any>) => {
        setFilter({ ...filter, [ev.target.name]: ev.target.value });
    };

    const [families, fc, familyStatus, familyMessage] = useGetAPI(
        FamilyService.url,
        pagination.page + 1,
        pagination.limit,
        { search: filter.search },
        [filter.apply, pagination.page, pagination.limit],
        []
    );

    const handleRowsPerPageChange = (ev: any) => {
        setPagination({ ...pagination, limit: ev.target.value });
    };

    const handleShowFamilyMembers = (familyId: number) => {
        setActiveFamily(familyId);
        setShowMembers(true);
    };

    const handlePageChange = (ev: any, page: number) => {
        setPagination({ ...pagination, page: page });
    };

    const handleDeleteFamily = async (familyId: number) => {
        setDeleteStatus({
            ...deleteStatus,
            [familyId]: APIRequestStatus.loading,
        });
        const [data, err] = await FamilyService.deleteSoft(familyId);

        if (data) {
            setDeleteStatus({
                ...deleteStatus,
                [familyId]: APIRequestStatus.success,
            });
            setSearchTrigger((n) => n + 1);
        } else {
            setDeleteStatus({
                ...deleteStatus,
                [familyId]: APIRequestStatus.error,
            });
        }
    };
    const headerVariables = useMemo(() => {
        if (!user?.baseUser?.userRegions.length) return;

        console.log(user.baseUser.userRegions);

        try {
            return JSON.parse(user?.baseUser?.userRegions[0].campus.header);
        } catch (error) {
            return {
                campusName: false,
                campusAddress: false,
                clientEmail: false,
                clientPhone: false,
            };
        }
    }, [user]);

    return (
        <>
            <Grid container spacing={1} alignItems="center">
                {familyStatus === APIRequestStatus.loading && (
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                )}

                {/* <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                > */}

                <Grid container spacing={1}>
                    <Grid item lg={4}>
                        <Search filter={filter} setFilter={setFilter} />
                    </Grid>

                    <Grid item lg={4}>
                        <Button
                            color="primary"
                            fullWidth
                            variant="contained"
                            onClick={() =>
                                setFilter({
                                    ...filter,
                                    apply: filter.apply + 1,
                                })
                            }
                            style={{ paddingTop: "10px" }}
                            size="small"
                        >
                            Search
                        </Button>
                    </Grid>
                    <Grid item lg={4}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <TablePagination
                                rowsPerPageOptions={[10, 50, 100]}
                                onPageChange={handlePageChange}
                                onRowsPerPageChange={handleRowsPerPageChange}
                                count={fc}
                                rowsPerPage={pagination.limit}
                                component="div"
                                page={pagination.page}
                                style={{ flex: "1" }}
                            />

                            <PrintTable
                                componentRef={studentFormRef}
                                setPrintMode={setPrintMode}
                                printMode={printMode}
                            />
                        </div>
                    </Grid>
                </Grid>

                {/* </div> */}

                <Grid item xs={12}>
                    <div ref={studentFormRef}>
                        {printMode && (
                            <HeaderStructure
                                selected={headerVariables}
                                campus={user.baseUser.userRegions[0].campus}
                                leftImage={"/images/icon.png"}
                                color={"black"}
                                additionalRow={"family"}
                            />
                        )}
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Members</TableCell>
                                        {/* <TableCell>Receipt</TableCell>
                                <TableCell>Collect Fees</TableCell> */}

                                        {user?.baseUser?.ops.includes(
                                            "GENERATE RECEIPT"
                                        ) ? (
                                            <TableCell>Receipt</TableCell>
                                        ) : null}

                                        {user?.baseUser?.ops.includes(
                                            "COLLECT FEE"
                                        ) ? (
                                            <TableCell>Collect Fees</TableCell>
                                        ) : null}

                                        {user?.baseUser?.ops.includes(
                                            "UPDATE FAMILY"
                                        ) ? (
                                            <TableCell>Edit</TableCell>
                                        ) : null}

                                        {user?.baseUser?.ops.includes(
                                            "DELETE FAMILY"
                                        ) ? (
                                            <TableCell>Edit</TableCell>
                                        ) : null}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {families.map((family: any) => (
                                        <TableRow key={family.id}>
                                            <TableCell>{family.id}</TableCell>
                                            <TableCell>{family.name}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() =>
                                                        handleShowFamilyMembers(
                                                            family.id
                                                        )
                                                    }
                                                    size="small"
                                                >
                                                    <ListIcon fontSize="small" />
                                                </IconButton>
                                            </TableCell>

                                            {user?.baseUser?.ops.includes(
                                                "GENERATE RECEIPT"
                                            ) ? (
                                                <TableCell>
                                                    <IconButton
                                                        onClick={() => {
                                                            window
                                                                .open(
                                                                    `/family/receipt?family=${family.id}`,
                                                                    "_blank"
                                                                )
                                                                ?.focus();
                                                        }}
                                                        size="small"
                                                    >
                                                        <ReceiptOutlined fontSize="small" />
                                                    </IconButton>
                                                </TableCell>
                                            ) : null}

                                            {user?.baseUser?.ops.includes(
                                                "COLLECT FEE"
                                            ) ? (
                                                <TableCell>
                                                    <IconButton
                                                        onClick={() => {
                                                            window
                                                                .open(
                                                                    `/collect-fees?family=${family.id}`,
                                                                    "_blank"
                                                                )
                                                                ?.focus();
                                                        }}
                                                        size="small"
                                                    >
                                                        <AttachMoneyOutlined fontSize="small" />
                                                    </IconButton>
                                                </TableCell>
                                            ) : null}

                                            {user?.baseUser?.ops.includes(
                                                "UPDATE FAMILY"
                                            ) ? (
                                                <TableCell>
                                                    <IconButton
                                                        onClick={() => {
                                                            window
                                                                .open(
                                                                    `/edit-family?id=${family.id}`,
                                                                    "_blank"
                                                                )
                                                                ?.focus();
                                                        }}
                                                        size="small"
                                                    >
                                                        <EditOutlined fontSize="small" />
                                                    </IconButton>
                                                </TableCell>
                                            ) : null}

                                            {user?.baseUser?.ops.includes(
                                                "DELETE FAMILY"
                                            ) ? (
                                                <TableCell>
                                                    {deleteStatus[family.id] ===
                                                    APIRequestStatus.loading ? (
                                                        <Icon>
                                                            <CircularProgress size="1em" />
                                                        </Icon>
                                                    ) : (
                                                        <IconButton
                                                            onClick={() =>
                                                                handleDeleteFamily(
                                                                    family.id
                                                                )
                                                            }
                                                            size="small"
                                                        >
                                                            <DeleteOutlined fontSize="small" />
                                                        </IconButton>
                                                    )}
                                                </TableCell>
                                            ) : null}
                                        </TableRow>
                                    ))}
                                </TableBody>
                                {printMode && <Footer span={6} />}
                            </Table>
                        </TableContainer>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    {activeFamily && (
                        <ShowFamilyMembers
                            show={showMembers}
                            setShow={setShowMembers}
                            members={
                                families.find((f: any) => f.id === activeFamily)
                                    .members
                            }
                        />
                    )}
                </Grid>
            </Grid>{" "}
        </>
    );
};

// import {
//     Button,
//     CircularProgress,
//     MenuItem,
//     Paper,
//     TextField,
// } from "@mui/material";
// import { useContext, useEffect, useState } from "react";
// import { CampusService } from "../../../services/CampusService";
// import { AppContext } from "../../AppContext";
// import { HeaderComponents } from "./HeaderComponents";
// import { HeaderStructure } from "./HeaderStructure";

// const defaultSelected = {
//     campusName: false,
//     campusAddress: false,
//     clientEmail: false,
//     clientPhone: false,
// };

// export const Header = ({
//     updateMode = false,
//     _campus = "",
//     leftImage,
//     rightImage,
//     additionalRow,
// }: any) => {
//     const [campus, setCampus] = useState(_campus);
//     const [campuses, setCampuses] = useState<any[]>([]);
//     const [header, setHeader] = useState({});
//     const [selected, setSelected] = useState(defaultSelected);
//     const appContext = useContext(AppContext);
//     const { feedback, setFeedback, user } = useContext(AppContext);

//     const getCampuses = async () => {
//         if (appContext.user) {
//             const [data, err] = await CampusService.getCampus();

//             if (data) {
//                 setCampuses(data.rows);
//             }
//         }
//     };

//     const handleChange = (ev: any) => {
//         const value = ev.target.value;
//         setCampus(value);
//     };

//     const handleUpdate = async () => {
//         setFeedback({
//             ...feedback,
//             loading: true,
//         });
//         setHeader({ ...header, header: { selected } });
//         const [data, err]: any = await CampusService.editCampus(campus, {
//             header: JSON.stringify(selected),
//         });

//         if (data) {
//             setFeedback({ ...feedback, loading: false });
//         }
//     };

//     useEffect(() => {
//         getCampuses();
//     }, [appContext.user]);

//     useEffect(() => {
//         if (campus && campuses.length > 0) {
//             const targetCampus = campuses.find(
//                 (_c) => _c.id == (_campus || campus)
//             );
//             const _header = JSON.parse(
//                 targetCampus.header || JSON.stringify(defaultSelected)
//             );
//             setSelected(_header);
//         }
//     }, [campus, campuses, _campus]);

//     return (
//         <Paper elevation={0} sx={{ p: 2 }}>
//             {updateMode && (
//                 <TextField
//                     name="campus"
//                     required
//                     select
//                     fullWidth
//                     sx={{ my: 2 }}
//                     label="Campus"
//                     value={campus}
//                     onChange={(ev) => handleChange(ev)}
//                 >
//                     {campuses.map((campus: any, idx) => (
//                         <MenuItem value={campus.id} key={idx}>
//                             {campus.name}
//                         </MenuItem>
//                     ))}
//                 </TextField>
//             )}

//             <HeaderStructure
//                 selected={selected}
//                 leftImage={leftImage}
//                 rightImage={rightImage}
//                 campus={campuses.find((c) => c.id === (_campus || campus))}
//                 additionalRow={additionalRow}
//                 color="white"
//             />

//             {updateMode && (
//                 <HeaderComponents
//                     selected={selected}
//                     setSelected={setSelected}
//                 />
//             )}

//             {updateMode && (
//                 <Button
//                     variant="outlined"
//                     sx={{ mt: 2 }}
//                     onClick={handleUpdate}
//                     disabled={feedback.loading}
//                     endIcon={
//                         feedback.loading ? (
//                             <CircularProgress size="1em" />
//                         ) : null
//                     }
//                 >
//                     {feedback.loading ? "updating header..." : "update header"}
//                 </Button>
//             )}
//         </Paper>
//     );
// };

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    Paper,
    TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CampusService } from "../../../services/CampusService";
import { UserService } from "../../../services/UserService";
import { AppContext } from "../../AppContext";
import { HeaderComponents } from "./HeaderComponents";
import { HeaderStructure } from "./HeaderStructure";

const defaultSelected = {
    campusName: false,
    campusAddress: false,
    clientEmail: false,
    clientPhone: false,
};

export const Header = ({
    updateMode = false,
    _campus = "",
    leftImage,
    rightImage,
    additionalRow,
}: any) => {
    const [campus, setCampus] = useState(_campus);
    const history = useHistory();
    const [campuses, setCampuses] = useState<any[]>([]);
    const [header, setHeader] = useState({});
    const [selected, setSelected] = useState(defaultSelected);
    const [dialogOpen, setDialogOpen] = useState(false); // State for dialog
    const appContext = useContext(AppContext);
    const { feedback, setFeedback, user, setUser, setUserType } =
        useContext(AppContext);

    const getCampuses = async () => {
        if (appContext.user) {
            const [data, err] = await CampusService.getCampus();

            if (data) {
                setCampuses(data.rows);
            }
        }
    };

    const handleChange = (ev: any) => {
        const value = ev.target.value;
        setCampus(value);
    };

    const handleUpdate = async () => {
        setFeedback({
            ...feedback,
            loading: true,
        });
        setHeader({ ...header, header: { selected } });
        const [data, err]: any = await CampusService.editCampus(campus, {
            header: JSON.stringify(selected),
        });

        if (data) {
            setFeedback({ ...feedback, loading: false });
            setDialogOpen(true); // Open the dialog on successful update
        }
    };

    const handleLogout = async () => {
        const [data, err] = await UserService.logoutUser();
        setUser(null);
        setUserType(null);

        if (data) {
            history.push("/login");
        }
    };

    const handleIgnore = () => {
        setDialogOpen(false); // Simply close the dialog
    };

    useEffect(() => {
        getCampuses();
    }, [appContext.user]);

    useEffect(() => {
        if (campus && campuses.length > 0) {
            const targetCampus = campuses.find(
                (_c) => _c.id == (_campus || campus)
            );
            const _header = JSON.parse(
                targetCampus.header || JSON.stringify(defaultSelected)
            );
            setSelected(_header);
        }
    }, [campus, campuses, _campus]);

    return (
        <Paper elevation={0} sx={{ p: 2 }}>
            {updateMode && (
                <TextField
                    name="campus"
                    required
                    select
                    fullWidth
                    sx={{ my: 2 }}
                    label="Campus"
                    value={campus}
                    onChange={(ev) => handleChange(ev)}
                >
                    {campuses.map((campus: any, idx) => (
                        <MenuItem value={campus.id} key={idx}>
                            {campus.name}
                        </MenuItem>
                    ))}
                </TextField>
            )}

            <HeaderStructure
                selected={selected}
                leftImage={leftImage}
                rightImage={rightImage}
                campus={campuses.find((c) => c.id === (_campus || campus))}
                additionalRow={additionalRow}
                color="white"
            />

            {updateMode && (
                <HeaderComponents
                    selected={selected}
                    setSelected={setSelected}
                />
            )}

            {updateMode && (
                <Button
                    variant="outlined"
                    sx={{ mt: 2 }}
                    onClick={handleUpdate}
                    disabled={feedback.loading}
                    endIcon={
                        feedback.loading ? (
                            <CircularProgress size="1em" />
                        ) : null
                    }
                >
                    {feedback.loading ? "updating header..." : "update header"}
                </Button>
            )}

            {/* Dialog for logout/ignore prompt */}
            <Dialog open={dialogOpen} onClose={handleIgnore}>
                <DialogTitle>Changes Applied</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Your header changes have been saved. To see the changes,
                        you need to logout and log back in. Would you like to
                        logout now?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleIgnore} color="secondary">
                        Ignore
                    </Button>
                    <Button onClick={handleLogout} color="primary" autoFocus>
                        Logout
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
};

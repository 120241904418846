import { Visibility } from "@mui/icons-material";
import {
    Box,
    CircularProgress,
    IconButton,
    Modal,
    useTheme,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { S3Service } from "../../../services/S3Service";
import { StudentService } from "../../../services/StudentService";
import { awsDirStruct, dateFormatter } from "../../constants";
import { Certificate, CertificateComponents } from "../Certificates";
import Academic from "../Variants/Academic";

type TPreviewCertificateData = {
    id: number;
    certificateStatus?: "original" | "provisional";
    classroom: { id: number; class: string; public: boolean };
    certificate: { id: number; type: string; name: string; content: string };
    baseUser?: any;
    issuedTo?: string;
    certificateName?: string;
    operator?: string;
};
export type Footer = {
    id: number | string;
    label: string;
    checked: boolean;
} & ({ type: "image"; image: File; url: string } | { type: "text" });

type TPreviewCertificateProps = { data: TPreviewCertificateData };

function PreviewCertificate({ data }: TPreviewCertificateProps) {
    const [open, setOpen] = useState(false);
    const [certificate, setCertificate] = useState<Certificate>({} as any);
    const [placeholders, setPlaceHolder] = useState({
        "[Name]": "T-Name",
        "[Father_Name]": "T-fatherName",
        "[Campus]": "T-campus",
        "[Date_of_Birth]": "T-dob",
        "[Gender]": "T-male",
        "[File_No.]": "T-FileNo",
        "[SLC_No.]": "T-slcNo",
    });
    const [infodata, setInfoData] = useState<any>();
    const theme = useTheme();

    const getData = async () => {
        const [_data, err] = await StudentService.getStudent(1, 1, {
            baseUser: data?.baseUser?.id,
        });
        if (_data.rows.length > 0) {
            setInfoData(_data.rows[0]);
            setPlaceHolder(() => {
                const filled = {
                    "[Name]": _data.rows[0].baseUser.name,
                    "[Father_Name]": _data.rows[0].fatherName,
                    "[Campus]": _data.rows[0].campus.name,
                    "[Gender]": _data.rows[0].gender,
                    "[File_No.]": _data.rows[0].fileNo,
                    "[SLC_No.]": _data.rows[0].slcNo,
                    "[Date_of_Birth]": dateFormatter.format(
                        new Date(_data.rows[0].dob)
                    ),
                };

                fillTemplate(data, filled);

                return filled;
            });
        }
    };

    const isDuplicate = useMemo(
        () => data?.certificateStatus === "provisional",
        [data?.certificateStatus]
    );

    //
    const toggleOpen = () => setOpen(!open);

    //
    //

    const updateText = (
        copy: {
            [key: string]: {
                text: string;
                placeholders: string[];
                color: string;
                fontSize: number | string;
            };
        },
        placeholders: { [key: string]: string }
    ) => {
        const replacedFields = Object.entries(copy).map(([key, val]) => ({
            [key]: {
                text: (val.text ?? "")
                    .split(" ")
                    .map((word: string) =>
                        Object.keys(placeholders).includes(word)
                            ? placeholders[word]
                            : word
                    )
                    .join(" "),
            },
        }));

        return replacedFields.reduce(
            (prev: any, curr: any) => ({ ...prev, ...curr }),
            {}
        );
    };

    function fillTemplate(
        data: TPreviewCertificateData,
        _placeholders: { [key: string]: string }
    ) {
        const { id, name, content, type } = data.certificate;

        const fields: CertificateComponents = JSON.parse(content);

        const placeholder_after_replace: any = Object.fromEntries(
            Object.entries(fields).map(([key, field]: any) =>
                field.placeholders
                    ? [
                          key,
                          {
                              ...field,
                              text: updateText({ [key]: field }, _placeholders)[
                                  key
                              ].text,
                          },
                      ]
                    : [key, field]
            )
        );

        const imagesInFooter = (fields.footer?.fields ?? []).filter(
            (item) => item.type === "image"
        );

        if (imagesInFooter.length > 0) {
            getImagesFromS3(imagesInFooter, id);
        }

        setCertificate({
            id,
            name,
            fields: placeholder_after_replace,
            type,
        });
    }

    //
    async function getImagesFromS3(images: Footer[], certificateId: number) {
        const key = `${awsDirStruct.org.picture}/${certificateId}/footer/`;

        const promises = images.map((item) =>
            S3Service.getS3ByKey(key + item.id)
        );

        Promise.allSettled(promises).then((res) => {
            const urls: string[] = [];

            res.forEach((item) => {
                if (item.status === "fulfilled") {
                    urls.push(item.value[0].url);
                }
            });

            setCertificate((c) => ({
                ...c,
                fields: {
                    ...c.fields,
                    footer: c.fields.footer
                        ? {
                              ...c.fields.footer,
                              fields: c.fields.footer?.fields.map(
                                  (item, index) => ({
                                      ...item,
                                      url: urls[index],
                                  })
                              ),
                          }
                        : undefined,
                },
            }));
        });
    }

    useEffect(() => {
        fillTemplate(data, placeholders);
    }, [data.baseUser]);

    useEffect(() => {
        getData();
    }, [data]);

    if (!certificate.fields)
        return <CircularProgress size={theme.spacing(2)} />;

    return (
        <Box>
            <IconButton onClick={toggleOpen} size="small">
                <Visibility fontSize="small" />
            </IconButton>

            <Modal
                open={open}
                onClose={toggleOpen}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Box width={"50%"}>
                    <Academic
                        isDuplicate={isDuplicate}
                        fields={certificate.fields}
                        logoPosition={certificate.fields.header.position}
                        baseUser={data?.baseUser}
                    />
                </Box>
            </Modal>
        </Box>
    );
}

export default PreviewCertificate;

import {
    Alert,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { usePostAPI } from "../../../services/Hooks";
import { SessionService } from "../../../services/SessionService";
import { StudentAttendanceService } from "../../../services/StudentAttendanceService";
import { StudentService } from "../../../services/StudentService";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";
import { baseAPI } from "../../constants";
import FeedbackSnackbar from "../../TestResult/FeedBackSnackBar";
import { StudentAttendanceRow } from "./StudentAttendanceRow";
import { UpdateStudentAttendanceRow } from "./UpdateStudentAttendanceRow";

const AddStudentAttendance = () => {
    const theme = useTheme();
    const attendanceOptions = [
        {
            label: "Present",
            status: "present",
            id: "present",
            color: theme.palette.success.main,
        },
        {
            label: "Absent",
            status: "absent",
            id: "absent",
            color: theme.palette.error.main,
        },
        { label: "Leave", status: "leave", id: "leave", color: "#fdd835" },
        {
            label: "Late",
            status: "late",
            id: "late",
            color: theme.palette.warning.main,
        },
        {
            label: "Off Day",
            status: "offDay",
            id: "offDay",
            color: theme.palette.info.main,
        },

        {
            label: "Sick Leave",
            status: "sickleave",
            id: "sickLeave",
            color: theme.palette.secondary.main,
        },
    ];

    const { user, feedback, setFeedback } = useContext(AppContext);
    const [attendanceList, setAttendanceList] = useState<any>();
    const [lastSession, setLastSession] = useState<any>();
    const [loading, setLoading] = useState<any>(null);
    const history = useHistory();
    const [students, setStudents] = useState<any>();
    const [pagination, setPagination] = useState({
        page: 0,
        count: 0,
        limit: 100,
    });

    const [leavePagination, setLeavePagination] = useState({
        page: 0,
        count: 0,
        limit: 100,
    });
    const [filter, setFilter] = useState<any>({
        campus: "",
        session: "",
        classroom: "",
        section: "",
        status: "",
        admissionStatus: "",
        apply: 0,
    });
    const [studentAttendanceInfo, setStudentAttendanceInfo] = useState({
        attendanceDate: new Date().toISOString().split("T")[0],
        subject: "",
        user: "",
        campus: "",
        classroom: "",
        type: "",
        session: "",
        section: "",
    });

    const [studentAttendance, setStudentAttendance] = useState<{
        [key: number]: any;
    }>({});
    const [studentLeaveAttendance, setStudentLeaveAttendance] = useState<{
        [key: number]: any;
    }>({});

    const isAllChecked = useMemo(() => {
        const { page, limit } = pagination;

        const start = page * limit;
        const end = (page + 1) * limit - 1;

        const checked = Object.values(studentAttendance)
            .slice(start, end)
            .every((x) => x.checked);

        return checked;
    }, [studentAttendance]);

    const isAllUpdatedChecked = useMemo(() => {
        const { page, limit } = leavePagination;

        const start = page * limit;
        const end = (page + 1) * limit - 1;

        const checked = Object.values(studentLeaveAttendance)
            .slice(start, end)
            .every((x) => x.checked);

        return checked;
    }, [studentLeaveAttendance]);

    const isSomeChecked = useMemo(() => {
        const { page, limit } = pagination;

        const start = page * limit;
        const end = (page + 1) * limit - 1;

        const checked =
            !isAllChecked &&
            Object.values(studentAttendance)
                .slice(start, end)
                .some((x) => x.checked);

        return checked;
    }, [studentAttendance]);

    function handleAllCheck(
        e: ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) {
        setStudentAttendance((curr) => {
            const allChecked = Object.fromEntries(
                Object.entries(curr).map(([k, v], index) => {
                    const { page, limit } = pagination;

                    const start = page * limit;
                    const end = (page + 1) * limit;

                    if (index >= start && index < end) {
                        return [
                            k,
                            {
                                ...v,
                                checked,
                            },
                        ];
                    }

                    return [k, v];
                })
            );

            return allChecked;
        });
    }

    const [clearFilters, setClearFilters] = useState(false);

    const getStudent = async () => {
        setLoading(true);
        const [data, err] = await StudentService.getStudent(
            pagination.page + 1,
            pagination.limit,
            {
                campus: studentAttendanceInfo.campus ?? null,
                session: studentAttendanceInfo.session ?? null,
                classroom: studentAttendanceInfo.classroom ?? null,
                section: studentAttendanceInfo.section ?? null,
            }
        );

        if (data?.rows?.length) {
            setStudents(data.rows);
            const baseUserIds = (attendanceList ?? []).map(
                (attlist: any) => attlist.student.baseUser.id
            );

            // Ensure baseUserIds is always an array
            const filteredData = data.rows.filter(
                (item: any) => !baseUserIds.includes(item.baseUser.id)
            );

            const studentData = (
                filteredData.length > 0 ? filteredData : data.rows
            ).reduce((prev: any, curr: any) => {
                const isChecked =
                    studentAttendance[curr?.baseUser?.id]?.checked ??
                    !!attendanceList.length;

                return {
                    ...prev,
                    [curr?.baseUser?.id]: {
                        checked: isChecked,
                        student: curr.id,
                    },
                };
            }, {});

            setStudentAttendance(studentData);
            setPagination({
                ...pagination,
                count: data?.count ?? 0,
            });
            setLoading(false);
        } else {
            setStudents([]);
            setStudentAttendance({});
            setLoading(false);
        }
    };

    const getAttendance = async () => {
        const date = new Date();
        const formattedDate = date.toISOString().split("T")[0];
        const [data, err] = await StudentAttendanceService.getAttendance(
            pagination.page + 1,
            pagination.limit,
            {
                campus: studentAttendanceInfo.campus,
                classroom: studentAttendanceInfo.classroom,
                section: studentAttendanceInfo.section,
                session: studentAttendanceInfo.session,
                attendanceDate: studentAttendanceInfo.attendanceDate,
                leaveStatus: "accepted",
                type: "dayWise",
            }
        );

        if (data?.rows?.length) {
            setAttendanceList(data.rows);
            setLeavePagination({
                ...leavePagination,
                count: data?.count ?? 0,
            });

            setStudentLeaveAttendance(
                data.rows.reduce((prev: any, curr: any) => {
                    const date = new Date(curr.lateDate);
                    const formattedTime =
                        date.getHours().toString().padStart(2, "0") +
                        ":" +
                        date.getMinutes().toString().padStart(2, "0");

                    const formattedDate =
                        date.toJSON().split("T")[0] + "T" + formattedTime;

                    return {
                        ...prev,
                        [curr.student.baseUser.id]: {
                            // baseUserId: curr.student.baseUser.id,
                            attendanceStatus: curr.attendanceStatus,
                            leaveApplication: curr.leaveApplication,
                            description: curr.description,
                            applicationType: curr.applicationType,
                            student: curr.student.id,
                            id: curr.id,
                            checked: true,
                            lateDate: formattedDate,
                        },
                    };
                }, {})
            );
        } else {
            setAttendanceList([]);
            setStudentAttendance([]);
        }
    };

    const [createStudentAttendance, response, status, message] = usePostAPI(
        `${baseAPI}/org/attandance/create`
        // (response) => {
        //     setFeedback({
        //         ...feedback,
        //         respneseDetail: response,
        //         show: true,
        //         severity: "success",
        //         message: response.message,
        //     });

        //     history.replace("/attendance");
        // },
        // (errResponse) => {

        //     setFeedback({
        //         ...feedback,
        //         respneseDetail: errResponse,
        //         show: true,
        //         severity: "error",
        //         message: response.message,
        //     });
        // }
    );

    async function handleSubmit(ev: any) {
        ev.preventDefault();
        setFeedback({ ...feedback, loading: true });
        const checkedResults = Object.values(studentAttendance).filter(
            (student) => student.checked
        );

        const allHaveAttendanceStatus = Object.values(studentAttendance)?.every(
            (x) => Boolean(x.attendanceStatus)
        );

        if (!checkedResults || checkedResults.length == 0) {
            setFeedback({
                loading: false,
                message:
                    "You must checked a row in order to take an attendance",
                show: true,
                severity: "error",
            });
        } else if (allHaveAttendanceStatus == false) {
            setFeedback({
                loading: false,
                message: "Must provide attendance status for each   student",
                show: true,
                severity: "error",
            });

            return;
        } else if (studentAttendanceInfo.attendanceDate == "") {
            setFeedback({
                loading: false,
                message:
                    "Must provide attendace date in order to take attendace",
                show: true,
                severity: "error",
            });
        } else if (studentAttendanceInfo.user == "") {
            setFeedback({
                loading: false,
                message: "Must provide a user in order to take attendance",
                show: true,
                severity: "error",
            });
        } else if (
            studentAttendanceInfo.type == "subject" &&
            studentAttendanceInfo.subject == ""
        ) {
            setFeedback({
                loading: false,
                message:
                    "Must provide a subject in order to take attendance subjectWise",
                show: true,
                severity: "error",
            });
        } else if (studentAttendanceInfo.section == "") {
            setFeedback({
                loading: false,
                message: "Must provide a section in order to take attendance",
                show: true,
                severity: "error",
            });
        } else if (studentAttendanceInfo.type == "") {
            setFeedback({
                loading: false,
                message:
                    "Must provide attendance type in order to take attendance",
                show: true,
                severity: "error",
            });
        } else {
            createStudentAttendance(
                {
                    ...studentAttendanceInfo,
                    attendanceList: checkedResults,
                },
                {}
            );

            setFeedback({
                loading: false,
                message: "",
                show: false,
                severity: "success", // Optional: Use success if appropriate
            });

            if (
                studentAttendanceInfo.type == "subject" &&
                studentAttendanceInfo.subject != null
            ) {
                const addLeaveStudentSubjectWise = Object.values(
                    studentLeaveAttendance
                );

                for (const singleLeaveStudent of addLeaveStudentSubjectWise) {
                    const [data, err] =
                        await StudentAttendanceService.createSingleStudentAttendance(
                            {
                                attendanceDate: new Date()
                                    .toISOString()
                                    .split("T")[0],
                                subject: studentAttendanceInfo.subject,
                                user: user?.baseUser?.id,
                                campus:
                                    user?.baseUser?.userRegions?.length > 1
                                        ? ""
                                        : user?.baseUser?.userRegions[0]?.campus
                                              ?.id,
                                classroom: studentAttendanceInfo.classroom,
                                type: "subject",
                                session: studentAttendanceInfo.session,
                                section: studentAttendanceInfo.section,

                                attendanceStatus:
                                    singleLeaveStudent.attendanceStatus,
                                leaveApplication:
                                    singleLeaveStudent.leaveApplication,
                                description: singleLeaveStudent.description,
                                applicationType:
                                    singleLeaveStudent.applicationType,
                                student: singleLeaveStudent.student,
                                leaveStatus: "accepted",
                            }
                        );
                }
            }
        }
    }

    useEffect(() => {
        if (
            studentAttendanceInfo.campus &&
            studentAttendanceInfo.session &&
            studentAttendanceInfo.section &&
            studentAttendanceInfo.classroom &&
            studentAttendanceInfo.type
        ) {
            getAttendance();
            getStudent();
        }
    }, [
        filter.search,
        pagination.count,
        pagination.page,
        pagination.limit,
        studentAttendanceInfo.session,
        studentAttendanceInfo.classroom,
        studentAttendanceInfo.section,
    ]);

    useEffect(() => {
        if (studentAttendanceInfo.attendanceDate) {
            getAttendance();
            getStudent();
        }
    }, [studentAttendanceInfo.attendanceDate]);

    useEffect(() => {
        if (studentAttendanceInfo.type) {
            getAttendance();
            getStudent();
        }
    }, [studentAttendanceInfo.type]);

    useEffect(() => {
        if (studentAttendanceInfo.subject) {
            getAttendance();
            getStudent();
        }
    }, [studentAttendanceInfo.subject]);

    useEffect(() => {
        setStudentAttendanceInfo((curr: any) => {
            return {
                ...curr,
                campus:
                    user?.baseUser?.userRegions?.length > 1
                        ? ""
                        : user?.baseUser?.userRegions[0]?.campus?.id,

                user: user?.baseUser?.id,
            };
        });
    }, [user?.baseUser?.userRegions?.length]);

    const LastSession = async () => {
        try {
            const [data, err] = await SessionService.getLastSession(1, 1, {
                campus: studentAttendanceInfo.campus
                    ? studentAttendanceInfo.campus
                    : null,
            });

            if (err) {
                console.error("Error fetching last session:", err);
                return;
            }

            if (data && data.rows.length) {
                setStudentAttendanceInfo((prevData: any) => ({
                    ...prevData,
                    session: data.rows[0].id,
                }));
                setLastSession(data.rows[0]);
            } else {
                console.warn("No session data found.");
            }
        } catch (error) {
            console.error("Unexpected error in LastSession:", error);
        }
    };

    useEffect(() => {
        if (studentAttendanceInfo.campus) {
            LastSession();
        }
    }, [studentAttendanceInfo?.campus as any]);

    useEffect(() => {
        if (["idle", "loading"].includes(status)) return;

        setFeedback({
            ...feedback,
            respneseDetail: response,
            show: true,
            severity: status == "success" ? "success" : "error",
            message: message,
        });

        if (status == "success") {
            history.replace("/attendance");
        }
    }, [status, message, response]);

    return (
        <div>
            <Typography variant="h4" color={"primary"}>
                Create Student Attendance
            </Typography>

            <div>
                <Grid container style={{ paddingTop: "2rem" }} spacing={1}>
                    {user?.baseUser?.userRegions?.length > 1 && (
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                api="/org/campus"
                                setOutput={(c: any) =>
                                    setStudentAttendanceInfo({
                                        ...studentAttendanceInfo,
                                        campus: c?.id || "",
                                    })
                                }
                                label="Campus"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                    required: true,
                                }}
                                clear={clearFilters}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            api="/org/session"
                            setOutput={(c: any) =>
                                setStudentAttendanceInfo({
                                    ...studentAttendanceInfo,
                                    session: c.id || "",
                                })
                            }
                            label="Session"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                                required: true,
                            }}
                            clear={clearFilters}
                            apiParams={{
                                campus: studentAttendanceInfo.campus,
                                status: "active",
                            }}
                            key={lastSession?.id}
                            defaultValue={lastSession}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            api="/org/academics/classroom"
                            setOutput={(c: any) =>
                                setStudentAttendanceInfo({
                                    ...studentAttendanceInfo,
                                    classroom: c?.id || "",
                                })
                            }
                            label="Class"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                                required: true,
                            }}
                            clear={clearFilters}
                            apiParams={{
                                campus: studentAttendanceInfo.campus,
                                status: "active",
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            api="/org/academics/section"
                            setOutput={(c: any) =>
                                setStudentAttendanceInfo({
                                    ...studentAttendanceInfo,
                                    section: c.id || "",
                                })
                            }
                            label="Section"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                                required: true,
                            }}
                            clear={clearFilters}
                            apiParams={{
                                classroom: studentAttendanceInfo.classroom,
                                status: "active",
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            defaultOptions={[
                                {
                                    label: "Attendance By Day",
                                    status: "dayWise",
                                    id: "dayWise",
                                },
                                {
                                    label: "Attendance By Subject",
                                    status: "subject",
                                    id: "subject",
                                },
                            ]}
                            setOutput={(c: any) =>
                                setStudentAttendanceInfo({
                                    ...studentAttendanceInfo,
                                    type: c?.id || "",
                                })
                            }
                            label="Attendance Type"
                            labelKey="label"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                                required: true,
                            }}
                            clear={clearFilters}
                        />
                    </Grid>

                    {studentAttendanceInfo?.type == "subject" && (
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                api="/org/Academics/Subject"
                                setOutput={(c: any) =>
                                    setStudentAttendanceInfo({
                                        ...studentAttendanceInfo,
                                        subject: c?.id || "",
                                    })
                                }
                                label="Subject"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                }}
                                clear={clearFilters}
                                apiParams={{
                                    classroom: studentAttendanceInfo.classroom,
                                    status: "active",
                                }}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} md={4}>
                        <TextField
                            size="small"
                            label="Attendance Date"
                            fullWidth
                            value={studentAttendanceInfo.attendanceDate}
                            name="date"
                            onChange={(ev) => {
                                const selectedDate = ev.target.value;
                                const currentDate = new Date()
                                    .toISOString()
                                    .split("T")[0];

                                if (selectedDate <= currentDate) {
                                    setStudentAttendanceInfo({
                                        ...studentAttendanceInfo,
                                        attendanceDate: selectedDate,
                                    });
                                }
                            }}
                            required
                            type="date"
                            InputProps={{
                                inputProps: {
                                    max: new Date().toISOString().split("T")[0], // Set max attribute to today
                                },
                            }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <TablePagination
                        component="div"
                        onPageChange={(ev, page) =>
                            setPagination({ ...pagination, page: page })
                        }
                        onRowsPerPageChange={(ev) =>
                            setPagination({
                                ...pagination,
                                limit: parseInt(ev.target.value),
                                page: 0,
                            })
                        }
                        count={pagination.count}
                        page={pagination.page}
                        rowsPerPage={pagination.limit}
                        rowsPerPageOptions={[100, 250, 500]}
                    />
                </Grid>

                {studentAttendanceInfo.campus == "" ||
                studentAttendanceInfo.session == "" ||
                studentAttendanceInfo.classroom == "" ||
                studentAttendanceInfo.section == "" ||
                studentAttendanceInfo.type == "" ? (
                    <Alert severity="info">
                        Please select a campus,session,class,section and
                        attendanceType
                    </Alert>
                ) : students?.length == 0 ? (
                    <Alert severity="info" style={{ width: "100%" }}>
                        No Student Found{" "}
                    </Alert>
                ) : loading && students ? (
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <form
                        onSubmit={(ev) => {
                            handleSubmit(ev);
                        }}
                        style={{ paddingTop: "3rem" }}
                    >
                        <Grid item xs={12}>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow
                                            style={{ whiteSpace: "nowrap" }}
                                        >
                                            <TableCell>
                                                <FormControl>
                                                    <Checkbox
                                                        checked={isAllChecked}
                                                        onChange={
                                                            handleAllCheck
                                                        }
                                                        indeterminate={
                                                            isSomeChecked
                                                        }
                                                    />
                                                </FormControl>
                                            </TableCell>
                                            <TableCell>Sr No.</TableCell>
                                            <TableCell>Reg No.</TableCell>
                                            <TableCell>Enroll No</TableCell>
                                            <TableCell>File No</TableCell>
                                            <TableCell>Id</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Father Name</TableCell>

                                            <TableCell>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">
                                                        Attendance Status
                                                    </FormLabel>
                                                    <RadioGroup
                                                        row
                                                        onChange={(event) => {
                                                            const selectedId =
                                                                event.target
                                                                    .value;
                                                            setStudentAttendance(
                                                                Object.fromEntries(
                                                                    Object.entries(
                                                                        studentAttendance
                                                                    ).map(
                                                                        ([
                                                                            k,
                                                                            v,
                                                                        ]) => [
                                                                            k,
                                                                            {
                                                                                ...v,
                                                                                attendanceStatus:
                                                                                    selectedId ??
                                                                                    "",
                                                                            },
                                                                        ]
                                                                    )
                                                                )
                                                            );
                                                        }}
                                                    >
                                                        {attendanceOptions.map(
                                                            (option) => (
                                                                <FormControlLabel
                                                                    key={
                                                                        option.id
                                                                    }
                                                                    value={
                                                                        option.id
                                                                    }
                                                                    control={
                                                                        <Radio
                                                                            style={{
                                                                                color: `${option.color}`,
                                                                                opacity: 1,
                                                                            }}
                                                                        />
                                                                    }
                                                                    label={
                                                                        option.label
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </RadioGroup>
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {students &&
                                            (students ?? [])
                                                .filter((std: any) => {
                                                    const baseUserIds = (
                                                        attendanceList ?? []
                                                    ).map(
                                                        (att: any) =>
                                                            att.student.baseUser
                                                                .id
                                                    );
                                                    return !baseUserIds.includes(
                                                        std.baseUser.id
                                                    );
                                                })
                                                .map(
                                                    (coll: any, id: number) => (
                                                        <StudentAttendanceRow
                                                            count={
                                                                id +
                                                                1 +
                                                                pagination.limit *
                                                                    pagination.page
                                                            }
                                                            key={id}
                                                            singleStudent={coll}
                                                            studentAttendance={
                                                                studentAttendance
                                                            }
                                                            setStudentAttendance={
                                                                setStudentAttendance
                                                            }
                                                            checkAll={
                                                                isAllChecked
                                                            }
                                                            id={id + 1}
                                                        />
                                                    )
                                                )}

                                        {attendanceList &&
                                            attendanceList?.map(
                                                (coll: any, id: number) => (
                                                    <UpdateStudentAttendanceRow
                                                        count={
                                                            id +
                                                            1 +
                                                            leavePagination.limit *
                                                                leavePagination.page
                                                        }
                                                        key={id}
                                                        singleStudent={coll}
                                                        studentAttendance={
                                                            studentLeaveAttendance
                                                        }
                                                        setStudentAttendance={
                                                            setStudentLeaveAttendance
                                                        }
                                                        checkAll={
                                                            isAllUpdatedChecked
                                                        }
                                                        disable={true}
                                                        id={id + 1}
                                                    />
                                                )
                                            )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <div
                            style={{
                                paddingTop: "1rem",
                            }}
                        >
                            <Button
                                color="primary"
                                variant="outlined"
                                disabled={status === "loading"}
                                fullWidth
                                size="large"
                                type="submit"
                                endIcon={
                                    status === "loading" ? (
                                        <CircularProgress size="1rem" />
                                    ) : null
                                }
                            >
                                {status === "loading"
                                    ? "Creating Attendance Result..."
                                    : "Create  Attendance"}
                            </Button>
                        </div>
                    </form>
                )}
            </div>

            <FeedbackSnackbar feedback={feedback} setFeedback={setFeedback} />
        </div>
    );
};

export default AddStudentAttendance;

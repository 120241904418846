import {
    ArrowDownward,
    ArrowUpward,
    ClearAll,
    Visibility,
} from "@mui/icons-material";
import { Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useContext, useState } from "react";
import BaseAccordian from "../../BaseAccordian";
import { MultiPermissionAuthorize } from "../../MultiPermissionAuthorize";
import { FeeStructureService } from "../../services/FeeStructureService";
import AddView from "../AddView";
import { AppContext } from "../AppContext";
import { Autocomplete } from "../AutoComplete";
import { RUDTable } from "../Table/RUDTable";
import AddFeeStructure from "./AddFeeStructure";

const FeeStructure = () => {
    const { user } = useContext(AppContext);
    const [changes, setChanges] = useState(0);
    const [filter, setFilter] = useState<any>({
        search: "",
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        classroom: "",
        session: "",
        apply: 0,
    });

    const [clearFilters, setClearFilters] = useState(false);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            search: "",
            campus: "",
            apply: 0,
        });
    };

    const RowComponent = ({ row }: any) => {
        function openInNewTab() {
            window.open(`/show-fee-structure/${row.id}`, "_blank")?.focus();
        }

        return (
            <IconButton onClick={openInNewTab} size="small">
                <Visibility fontSize="small" />
            </IconButton>
        );
    };

    const ChangeFeeStructureSequence = ({ row, setChangesCount }: any) => {
        const handleLevelChange = async (direction: string) => {
            const [data, err] = await FeeStructureService.changeLevel(
                row.id,
                direction
            );

            if (data) {
                setChanges((c: number) => c + 1);
            }
        };

        return (
            <div>
                <IconButton
                    onClick={() => handleLevelChange("up")}
                    size="small"
                >
                    <Tooltip title="Move up">
                        <ArrowUpward fontSize="small" />
                    </Tooltip>
                </IconButton>

                <IconButton
                    onClick={() => handleLevelChange("down")}
                    size="small"
                >
                    <Tooltip title="Move down">
                        <ArrowDownward fontSize="small" />
                    </Tooltip>
                </IconButton>
            </div>
        );
    };

    return (
        <>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography
                        variant="h4"
                        color="primary"
                        gutterBottom
                        sx={{ pl: 1.5 }}
                    >
                        Fee Structure
                    </Typography>
                </Grid>

                <Grid item>
                    <MultiPermissionAuthorize ops={["CREATE FEE_STRUCTURE"]}>
                        <AddView
                            label="Add fee structure"
                            triggerRowsRefetch={() => setChanges(changes + 1)}
                        >
                            <AddFeeStructure />
                        </AddView>
                    </MultiPermissionAuthorize>
                </Grid>
            </Grid>

            <BaseAccordian>
                <Grid container spacing={1.5}>
                    {/* <Grid item xs={12} md={6} lg={3}>
                        <Autocomplete
                            api="/org/campus"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    campus: c?.id || "",
                                })
                            }
                            label="Campus"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                        />
                    </Grid> */}

                    {user?.baseUser?.userRegions?.length > 1 && (
                        <Grid item xs={12} md={6} lg={3}>
                            <Autocomplete
                                api="/org/campus"
                                setOutput={(c: any) =>
                                    setFilter({
                                        ...filter,
                                        campus: c?.id || "",
                                    })
                                }
                                label="Campus"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                }}
                                clear={clearFilters}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} md={6} lg={3}>
                        <Autocomplete
                            api="/org/academics/classroom"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    classroom: c?.id || "",
                                })
                            }
                            label="Class"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                            apiParams={{
                                campus: filter.campus ?? undefined,
                                status: "active",
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Autocomplete
                            api="/org/Session"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    session: c?.id || "",
                                })
                            }
                            label="Session"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                            apiParams={{
                                campus: filter.campus ?? undefined,
                                status: "active",
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Button
                            color="primary"
                            fullWidth
                            variant="contained"
                            onClick={() =>
                                setFilter({
                                    ...filter,
                                    apply: filter.apply + 1,
                                })
                            }
                            style={{ height: "100%" }}
                            size="small"
                        >
                            {" "}
                            Search
                        </Button>
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Tooltip title="Clear Filters">
                            <IconButton
                                onClick={handleFilterClear}
                                color="primary"
                            >
                                <ClearAll />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </BaseAccordian>

            <RUDTable
                getter={(page, limit) =>
                    FeeStructureService.getFeeStructure(page, limit, {
                        classroom: filter.classroom
                            ? filter.classroom
                            : undefined,
                        session: filter.session ? filter.session : undefined,
                    })
                }
                editor={FeeStructureService.editFeeStructure}
                deletor={
                    user?.baseUser?.ops.includes("DELETE FEE_STRUCTURE")
                        ? (id: string | number) =>
                              FeeStructureService.deleteFeeStructureSoft(id)
                        : undefined
                }
                rowsPreprocessor={(row) => ({
                    ...row,
                    deletionId: row.id,
                    classroomName: row.classroom.name,
                    sessionName: row.session.name,
                    categoryName: row.categories.name,
                    campusName: row.classroom.campus.name,
                })}
                readables={{
                    id: "ID",
                    classroomName: "Class",
                    sessionName: "Session",
                    campusName: "Campus",
                    ViewFeesStructure: "View Fees Structure",
                    Edit: "Edit",
                    Changesequence: "Change Sequence",
                }}
                customCols={[
                    {
                        header: "Changesequence",
                        content: (row) => (
                            <ChangeFeeStructureSequence
                                row={row}
                                setChangesCount={setChanges}
                            />
                        ),
                    },
                    {
                        header: "ViewFeesStructure",
                        content: (row) => <RowComponent row={row} />,
                    },
                    {
                        header: user?.baseUser?.ops.includes(
                            "UPDATE FEE_STRUCTURE"
                        )
                            ? "Edit"
                            : "",

                        content: (row) => (
                            <MultiPermissionAuthorize
                                ops={["UPDATE FEE_STRUCTURE"]}
                            >
                                <AddView
                                    label="Edit Fee Structure"
                                    updateMode
                                    triggerRowsRefetch={() =>
                                        setChanges(changes + 1)
                                    }
                                >
                                    <AddFeeStructure row={row} updateMode />
                                </AddView>
                            </MultiPermissionAuthorize>
                        ),
                    },
                ]}
                updatingAgents={[filter.apply, changes]}
                ops={{
                    read: "READ FEE_STRUCTURE",
                    edit: "",
                    delete: "DELETE FEE_STRUCTURE",
                }}
                storageKey="fee Structure"
            />
        </>
    );
};

export default FeeStructure;
